const handleRouter = {
  // Esportes
  inicio_esportes: '/esportes',
  esportes_regulamento: `/esportes/regulamento`,
  esportes_compartilhar_codigo_bilhete: '/esportes/compartilhar-codigo-bilhete',
  esportes_partida: '/esportes/partida',
  esportes_resultados: '/esportes/resultado',
  // Área do colaborador
  login_colaborador: "/esportes/colaborador/login",
  login_colaborador_inicio: "/esportes/colaborador/inicio",
  login_colaborador_caixa: "/esportes/colaborador/caixa",
  login_colaborador_bilhetes: "/esportes/colaborador/bilhetes",
  login_colaborador_clientes: "/esportes/colaborador/clientes",
  login_colaborador_clientes_form: "/esportes/colaborador/clientes/form",
  login_colaborador_alterar_senha: "/esportes/colaborador/alterar-senha",
  // JB
  inicio_jb: '/jb',
  jb_regulamento: `/jb/regulamento`,
  jb_compartilhar_codigo_bilhete: '/jb/compartilhar-codigo-bilhete',
  jb_resultado: '/jb/resultado',
  // Conta
  conta: `/conta`,
  saques: '/conta/saques',
  pedidos_saque: 'conta/pedidos-saque',
  alterar_senha: `/conta/alterar-senha`,
  conferir_bilhete: '/conta/conferir-bilhete',
  // Site
  sobre: '/sobre',
  
};

export default handleRouter;
