import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BsArrowLeft, BsCash, BsDoorOpen, BsPencil, BsReceiptCutoff, BsTrash } from 'react-icons/bs'
import handleRouter from '../../../utils/handle-router';
import { useNavigate } from 'react-router-dom';
import { UserQueries } from '../../../api/query/user';
import { useLazyQuery, useMutation } from '@apollo/client';
import handleErrorGql from '../../../utils/handle-error-gql';
import MoneyBRL from '../../../utils/money-brl';
import dateSimple from '../../../utils/date-simple';
import InlineLoading from '../../../components/inline-loading';
import { UsuarioMutations } from '../../../api/mutation/usuario';
import { Button } from '../../../components/buttons/button';

export default function ClientesColaborador() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [_index, _setIndex] = useState(-1)

    const [getClientesColaboradorLazy] = useLazyQuery(UserQueries.getAppClienteList);
    const [deleteClientePontoMutation] = useMutation(UsuarioMutations.deleteClientePonto);
    const [lista, setLista] = useState([]);

    useEffect(() => {
        getClientesColaborador();
    }, [])

    const getClientesColaborador = async (busca = '') => {
        if (loading) return;
        setLoading(true);
        try {
            let data = await getClientesColaboradorLazy({ variables: { busca } })
            if (data?.errors?.length) throw data.errors[0];
            if (!!data.data.appClienteList) {
                let _ = JSON.parse(data.data.appClienteList);
                setLista(_);
            }
        } catch (error) {
            let err = handleErrorGql(error);
            alert(err);
        }
        setLoading(false);
    }

    const deleteCliente = async ({ _id, nome }) => {
        if (loading) return;
        if (window.confirm(`Deseja realmente excluir o cliente ${nome}?`)) {
            let reload = false;
            setLoading(true);
            try {
                await deleteClientePontoMutation({ variables: { id: _id, apagar: true } });
                reload = true;
            } catch (error) {
                let err = handleErrorGql(error);
                alert(err);
            }
            setLoading(false);
            if (reload) getClientesColaborador();
        }

    }

    return (
        <div className="container pt-3">

            <div className="d-flex flex-row align-items-center gap-2">
                <div className="card px-2 py-1 rounded bg-danger text-white border-0 shadow-sm" style={{ cursor: 'pointer' }}>
                    <div className="d-flex flex-row align-items-center gap-2" onClick={() => navigate(handleRouter.login_colaborador_inicio)}>
                        <BsArrowLeft /> <span className='d-none d-sm-block'>Voltar</span>
                    </div>
                </div>
                <span className='fs-4 fw-bold'>Clientes</span>
            </div>
            <hr />

            <div className="d-flex flex-row">
                <Button onClick={() => navigate(handleRouter.login_colaborador_clientes_form)}>
                    Novo cliente
                </Button>
            </div>

            <InlineLoading loading={loading} />

            <div className="row">
                <div className="col-md-12 table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Telefone</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lista?.map((item) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>{item.nome}</td>
                                            <td>{item?.telefone || "Não informado"}</td>
                                            <td>
                                                <div className='d-flex flex-row gap-2'>
                                                    <button
                                                        type='button'
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => {
                                                            let params = new URLSearchParams();
                                                            params.append('id', item._id);
                                                            params.append('nome', item.nome);
                                                            params.append('telefone', item?.telefone || "");

                                                            navigate(handleRouter.login_colaborador_clientes_form + "?" + params.toString())
                                                        }}
                                                    >
                                                        <BsPencil />
                                                    </button>
                                                    <button
                                                        type='button'
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => deleteCliente(item)}
                                                    >
                                                        <BsTrash />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div >
    );
}

