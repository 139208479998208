import React from 'react';

export function LabelValor({ label, valor, className, onClick }) {
  return (
    <div
      onClick={onClick}
      style={{ fontSize: '.8rem' }}
      className={`d-flex flex-row justify-content-between fw-semibold ${className}`}
    >
      <small>{label}</small>
      <small>{valor}</small>
    </div>
  );
}
