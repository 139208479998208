import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BsAsterisk, BsCash, BsDoorOpen, BsHash, BsLock, BsPass, BsPerson, BsReceiptCutoff, BsUnlock } from 'react-icons/bs'
import handleRouter from '../../../utils/handle-router';
import { useNavigate } from 'react-router-dom';
import { ContextUsuario } from '../../../context/context-user';


export default function InicioColaborador() {
    const navigate = useNavigate();
    // const location = useLocation();
    const { limparSessaoColaborador } = useContext(ContextUsuario);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        console.log("Olá")
    }, [])

    const options = [
        {
            id: 1,
            description: 'Caixa',
            icon: <BsCash />,
            onClick: () => {
                navigate(handleRouter.login_colaborador_caixa)
            }
        },
        {
            id: 2,
            description: 'Bilhetes',
            icon: <BsReceiptCutoff />,
            onClick: () => {
                navigate(handleRouter.login_colaborador_bilhetes)
            }
        }
    ]


    return (
        <div className="container pt-3">
            <h3>Menu</h3>
            <hr />
            <h5 className='fw-bold'>Futebol</h5>
            <div className="row g-2">
                {
                    options.map((item) => {
                        return (
                            <div key={item.id} className="col-md-4">
                                <div
                                    onClick={item.onClick}
                                    className="card p-3 rounded bg-primary text-white border-0 shadow-sm"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="d-flex flex-row align-items-center gap-3">
                                        {item.icon} <span>{item.description}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <hr />
            <div className="row g-2 mb-3">
                <div className="col-md-4">
                    <div
                        onClick={() => {
                            navigate(handleRouter.login_colaborador_clientes)
                        }}
                        className="card p-3 rounded bg-primary text-white border-0 shadow-sm"
                        style={{ cursor: 'pointer' }}
                    >
                        <div className="d-flex flex-row align-items-center gap-3">
                            <BsPerson /> <span>Clientes</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div
                        onClick={() => {
                            navigate(handleRouter.login_colaborador_alterar_senha)
                        }}
                        className="card p-3 rounded bg-primary text-white border-0 shadow-sm"
                        style={{ cursor: 'pointer' }}
                    >
                        <div className="d-flex flex-row align-items-center gap-3">
                            <BsAsterisk /> <span>Alterar senha</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row g-2">
                <div className="col-md-4">
                    <div
                        className="card p-3 rounded bg-danger text-white border-0 shadow-sm"
                        style={{ cursor: 'pointer' }}
                        onClick={() => limparSessaoColaborador()}
                    >
                        <div className="d-flex flex-row align-items-center gap-3">
                            <BsDoorOpen /> <span>Sair</span>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

