import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useStackContext } from '../../../../context/context-stack';
import { handleText } from '../../../../utils/handle-text';
import CardFinalizaAposta from '../../../cards/card-finaliza-aposta';
import { LabelValor } from '../../finish/components/label-valor';
import { useTicketContext } from './ticket';
import { BiSearch } from 'react-icons/bi';
import InlineLoading from '../../../inline-loading';
import { UserQueries } from '../../../../api/query/user';
import handleErrorGql from '../../../../utils/handle-error-gql';
import dateSimple from '../../../../utils/date-simple';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUsuarioContext } from '../../../../context/context-user';
import { UsuarioMutations } from '../../../../api/mutation/usuario';
import { useFormik } from 'formik';

export default function TicketMobile() {
  const [showTicket, setShowTicket] = useState(false);
  const {
    header,
    loading,
    cart,
    form,
    setForm,
    apostar,
    usuario,
    setValorAposta,
    setCart,
    valorAposta,
    forma_pagamento
  } = useTicketContext();
  const { colaborador } = useUsuarioContext();
  const { setSidebarState, setHeightTicket } = useStackContext();
  const [qtd_jogos, cotacao, ...header_] = header;
  const [loadingBilhete, setLoadingBilhete] = useState(false);

  const [buscaApostaFutebolLazy] = useLazyQuery(UserQueries.buscaApostaFutebol);
  const [getClientesColaboradorLazy] = useLazyQuery(UserQueries.getAppClienteList);
  const [formasPagamento, setFormasPagamento] = useState([]);;

  const [showModal, setShowModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);

  const refBox = useCallback(element => {
    if (element) {
      setHeightTicket(element.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (!!form?.isValidatingBilhete) {
      setFormasPagamento([
        {
          label: "Validar bilhete",
          value: "VALIDANDO",
          id: 'group-payment-4',
        }
      ])
      setForm(_ => ({ ..._, forma_pagamento: 'VALIDANDO' }));
    } else {
      setFormasPagamento(forma_pagamento);
    }
  }, [form.isValidatingBilhete])



  function getRawValueAposta() {
    let valor_apostado = 0;
    if (valorAposta.value) valor_apostado = valorAposta.value;
    return Number(valor_apostado) || 0;
  }

  function getPossivelRetorno() {
    let possivel_retorno = 0;
    if (getRawValueAposta()) possivel_retorno = getRawValueAposta();
    for (let jogo of cart) {
      if (jogo?.cotacao_selecionada?.valor) {
        possivel_retorno *= 1 * jogo.cotacao_selecionada.valor;
      }
    }
    return possivel_retorno;
  }

  const buscaApostaFutebol = async (ev) => {
    ev.preventDefault();
    if (loadingBilhete) return;
    setLoadingBilhete(true);
    try {
      let data = await buscaApostaFutebolLazy({ variables: { cod: form.buscaCod }, fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' });
      if (data?.errors?.length) throw data.errors[0];
      if (!data?.data?.buscaApostaFutebol) throw new Error("Bilhete não encontrado para validação!");
      montaApostaFutebol(data.data.buscaApostaFutebol);

    } catch (error) {
      let err = handleErrorGql(error);
      alert(err);
    }
    setLoadingBilhete(false);
  }

  const montaApostaFutebol = (bilhete) => {
    if (!bilhete?._id) return;
    try {
      let jogos_bilhete = [];
      for (let jogo of bilhete.jogos_futebol) {
        let _ = {
          _id: jogo._id,
          base_id: jogo.base_id,
          casa: jogo.casa,
          fora: jogo.fora,
          cotacao_selecionada: {
            id: jogo.cotacao.id,
            sigla: jogo.cotacao.sigla,
            sigla_id: jogo.cotacao.sigla_id,
            valor: jogo.cotacao.valor
          },
          data_hora: jogo.data_hora,
          data_hora_br: dateSimple(jogo.data_hora, 0, 'DD/MM HH:mm'),
          liga: jogo.liga,
        }
        jogos_bilhete.push(_);
      }
      setCart(jogos_bilhete);
      let valor_bilhete = 100;
      if (bilhete?.valor_bruto > 0) valor_bilhete = bilhete.valor_bruto * 100;
      setValorAposta(handleText.maskNumber(valor_bilhete));
      setForm(_ => ({
        ..._,
        cliente: {
          _id: bilhete?.cliente_ponto_app?._id || null,
          nome: bilhete?.cliente_ponto_app?.nome || null,
          telefone: bilhete?.cliente_ponto_app?.telefone || null
        },
        isValidatingBilhete: bilhete.cod,
        isValidatingBilheteId: bilhete._id
      }));
    } catch (error) {

    }
  }

  const clearValidation = () => {
    setForm(_ => ({
      ..._,
      cliente: { _id: null, nome: null, telefone: null },
      isValidatingBilhete: null, isValidatingBilheteId: null, buscaCod: ""
    }))
    setCart([]);
    setValorAposta(handleText.maskNumber(null));
  }

  function canShowValidation() {
    if (colaborador && !!form?.isValidatingBilhete) return true;
    if (colaborador && !!!form?.isValidatingBilhete && cart?.length == 0) return true;
    if (colaborador && !!!form?.isValidatingBilhete && cart?.length > 0) return false;
    return false
  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [appClienteFormMutation] = useMutation(UsuarioMutations.deleteClientePonto)

  const getClientes = async () => {
    if (loadingClientes) return;
    setLoadingClientes(true);
    try {
      let data = await getClientesColaboradorLazy({ busca: '' });
      if (data?.errors?.length) throw data.errors[0];
      if (data.data.appClienteList) setClientes(JSON.parse(data.data.appClienteList));
    } catch (error) {
      let err = handleErrorGql(error);
      alert(err);
    }
    setLoadingClientes(false);
  }

  const handleSubmitCliente = async (values) => {
    if (loadingClientes) return;
    setLoadingClientes(true);
    let reload = false;
    try {
      let data = await appClienteFormMutation({ variables: { nome: values.nome } });
      if (data?.errors) throw data.errors;
      if (!!data.data?.appClienteForm) {
        let cli = JSON.parse(data.data.appClienteForm);
        setForm(_ => ({ ..._, cliente: cli }));
        handleCloseModal();
        formikCliente.resetForm();
      }
      reload = true;
    } catch (error) {
      let err = handleErrorGql(error)
      alert(err);
    }
    setLoadingClientes(false);
    if (reload) getClientes();

  }

  const formikCliente = useFormik({
    initialValues: {
      nome: ''
    },
    onSubmit: handleSubmitCliente
  })


  if (!showTicket) {
    return (
      <div className="bottom-0 bg-primary-main  z-3 w-100 position-fixed  d-md-none d-grid p-2 gap-2 text-light align-items-center ">
        <div className="row  justify-content-center  g-1 ">
          <div className="col-4 d-flex flex-column text-center   ">
            <small style={{ fontSize: '.8rem' }}>Possível retorno</small>
            <small className="fw-bold ">
              {handleText.asMoney(getPossivelRetorno())}
            </small>
          </div>

          <div className="col-4    d-flex align-items-center ">
            <input
              inputMode="numeric"
              style={{ fontSize: 16 }}
              value={valorAposta.maskedValue}
              className="btn-light  col form-control form-control-sm w-100 h-100 rounded-2"
              placeholder={'Valor'}
              onChange={({ target }) =>
                setValorAposta(handleText.maskNumber(target.value))
              }
            />
          </div>

          <div className="col-4  d-flex align-items-center ">
            <Button
              style={{ fontSize: '.8rem' }}
              className="btn btn-primary w-100 h-100 rounded-2"
              // disabled={cart.length == 0}
              onClick={async () => setShowTicket(true)}
            >
              {`Cupom`}
              {!!cart.length && (
                <span className="badge text-bg-secondary">
                  {cart.length}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (

      <>

        <Modal
          show={showTicket}
          fullscreen={true}
          onHide={() => setShowTicket(false)}
        >
          <Modal.Header
            className="bg-primary-main text-light py-2"
            closeButton
            closeVariant="white"
          >
            <Modal.Title>Cupom</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-2 py-1">
            {
              canShowValidation() && (
                <div className='card bg-primary border-0 p-2 mb-2'>
                  {
                    !!!form?.isValidatingBilhete && (
                      <form onSubmit={buscaApostaFutebol} className="d-flex flex-column gap-2">
                        <label className='text-white'>Validação de Bilhete</label>
                        <div className='d-flex flex-row gap-2'>
                          <input
                            type="text"
                            className='form-control form-control-sm'
                            placeholder='Código do bilhete'
                            disabled={!!form.isValidatingBilhete}
                            value={form.buscaCod}
                            onChange={(ev) => setForm(_ => ({ ..._, buscaCod: ev.target.value }))}
                          />
                          <button
                            className='btn btn-secondary btn-sm'
                            type='submit'
                            disabled={loadingBilhete}
                          >
                            <BiSearch />
                          </button>
                        </div>
                        <InlineLoading className={'text-white'} loading={loadingBilhete} />
                      </form>
                    )
                  }
                  {
                    !!form?.isValidatingBilhete && (
                      <>
                        <small className='text-white fw-bold'>Validando o bilhete: {form.isValidatingBilhete}</small>
                        <button
                          className="btn btn-danger btn-sm mt-2"
                          onClick={() => clearValidation()}
                        >
                          Remover
                        </button>
                      </>
                    )
                  }
                </div>
              )
            }
            <div className=" d-flex flex-column gap-1 ">
              {cart?.map(partida => (
                <CardFinalizaAposta {...{ partida }} key={partida?._id} />
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className=" d-block  bg-primary-main text-light py-2    ">
            <div className="">
              {
                colaborador && (
                  <LabelValor
                    className={'text-decoration-underline'}
                    onClick={() => handleShowModal()}
                    label={'Cliente'}
                    valor={!!form?.cliente?.nome ? form?.cliente?.nome : 'Selecione'}
                  />
                )
              }
              {
                [qtd_jogos, cotacao, ...header_].map(item => (
                  <LabelValor {...item} key={item.label} />
                ))
              }
              {
                form.forma_pagamento === 'SALDO' && (
                  <LabelValor
                    label={'Saldo'}
                    valor={handleText.formatValueToMoney(usuario?.saldo)}
                  />
                )
              }
            </div>
            <b className="text-start text-light">Formas de pagamento</b>
            <Form className='mb-2'>
              {
                formasPagamento.map(item => (
                  <Form.Check
                    checked={item.value === form.forma_pagamento}
                    onChange={ev =>
                      setForm(_ => ({
                        ..._,
                        forma_pagamento: item.value,
                      }))
                    }
                    key={item.id}
                    inline
                    label={item.label}
                    name={'group1'}
                    type={'radio'}
                    id={`inline-${item.id}`}
                  />
                ))
              }
            </Form>
            <Form.Group>
              <input
                style={{ fontSize: 16 }}
                value={valorAposta.maskedValue}
                className="form-control form-control-sm"
                placeholder={'Valor'}
                onChange={({ target }) => setValorAposta(handleText.maskNumber(target.value))}
              />
            </Form.Group>
            <div className="d-grid  ">
              <Button
                className={'fw-bold  '}
                onClick={async () => {
                  await apostar();
                  setSidebarState('close');
                  setShowTicket(false);
                }}
                disabled={loading}
              >
                {(form['forma_pagamento'] === 'PIX' || form['forma_pagamento'] === 'SALDO') && !usuario && 'FAZER LOGIN'}
                {(form['forma_pagamento'] === 'PIX' || form['forma_pagamento'] === 'SALDO') && usuario && 'FINALIZAR'}
                {(!form['forma_pagamento'] || form['forma_pagamento'] === 'COLABORADOR') && 'FINALIZAR'}
                {(form['forma_pagamento'] === 'VALIDANDO') && 'VALIDAR'}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          onShow={getClientes}
        >
          <Modal.Header closeButton>
            <Modal.Title>Clientes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InlineLoading loading={loadingClientes} />

            {
              !!!form?.cliente?._id && (
                <form className="row mb-3 g-2" onSubmit={formikCliente.handleSubmit}>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome do cliente"
                        onChange={formikCliente.handleChange('nome')}
                      />
                      <label>Informe o nome do cliente para criar</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button className='btn btn-primary btn-sm w-100' type='submit'>
                      Criar
                    </button>
                  </div>
                </form>
              )
            }

            {
              !!form?.cliente?._id && (
                <>
                  <div className="d-flex flex-column align-items-center mb-2 bg-success p-2 rounded">
                    <span>Cliente selecionado</span>
                    <span>{form.cliente.nome}</span>
                  </div>
                  <button className={'btn btn-danger btn-sm w-100'}
                    onClick={() => {
                      setForm(_ => ({ ..._, cliente: { _id: null, nome: null, telefone: null } }))
                    }}>
                    Remover seleção
                  </button>
                </>
              )
            }
            <hr />

            <div className="row g-2">
              {clientes.map(cliente => (
                <div className="col-md-12" key={cliente._id}>
                  <div
                    className="card d-flex flex-column p-2 rounded bg-primary text-white border-0 shadow-sm cursor-pointer"
                    onClick={() => {
                      setForm(_ => ({ ..._, cliente }))
                      handleCloseModal();
                    }}
                  >
                    <span>{cliente.nome}</span>
                    <span>{cliente?.telefone || "Sem telefone"}</span>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

      </>

    );
  }
}
