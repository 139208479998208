import { useEffect, useState } from "react";
import { BsExclamationDiamond } from 'react-icons/bs'

export default function InlineError({ error }) {
    const [err, setErr] = useState(null);

    useEffect(() => {
        if (error?.message) setErr(error.message);
        else if (typeof error == 'string') setErr(error);
        else setErr(null)
    }, [error])

    if (!err) return null;

    return (
        <div className="bg-danger bg-gradient rounded text-white px-3 py-2 my-2 d-flex flex-row gap-3 align-items-center justify-center">
            <small>{err}</small>
        </div >
    )

}