import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import axios from 'axios';
import { colaboradorStorage } from '../context/context-user';

export const mainPath = process.env.NODE_ENV === 'development' ? "http://192.168.68.123:4004" : 'https://api-2.tjsystem.net';
const httpLink = createHttpLink({ uri: mainPath });

const headerLink = setContext(() => {
  let sessao = localStorage.getItem('SESSAO');
  if (sessao) sessao = JSON.parse(sessao);

  let headers = {};
  headers['platform'] = 'FUTEBOL_SITE';
  headers['empresa-id'] = '5f5927a90ab43ddd7da94545';

  if (sessao?.token) headers['token'] = sessao.token;

  let sessao_colaborador = localStorage.getItem('C_SESSAO');
  if (sessao_colaborador) sessao_colaborador = JSON.parse(sessao_colaborador);

  if (sessao_colaborador?.token) {
    headers['sessao-colaborador'] = 1;
    headers['token'] = sessao_colaborador.token;
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  try {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.indexOf("Token expired") > -1) {
          let _sessao = colaboradorStorage.getSessao();
          if (_sessao) {
            colaboradorStorage.limpaSessao();
            window.location.replace('/esportes/colaborador/login');
            alert("Sessão expirada, efetue o login novamente!");
          }
        }
        // console.log(':locations', locations);
        // console.log(':path', path);
        // console.error(
        //   `[C:GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        // )
        return forward(operation);
      });
    }
    if (networkError) {
      if (networkError?.message?.indexOf("Response not successful: Received status code 500") > -1) {
        networkError.message = 'Sessão expirada, efetue o login novamente!';
      }
      if (networkError?.message?.indexOf("Network request failed") > - 1) {
        networkError.message = 'Por favor, verifique a sua conexão com a internet!';
      }
      if (networkError?.message?.indexOf("Failed to fetch") > - 1) {
        networkError.message = 'Por favor, verifique a sua conexão com a internet!';
      }
      // console.log(`[C:Network error]: ${networkError?.message}`);
    }
    return forward(operation);
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, headerLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

const api_routers = {
  posts: {
    saveAposta: '/aposta_online',
    localizacao_valida: '/localizacao_valida',
    saveApostaV2: '/aposta_online_v2',
    loginStep1: '/send_sms',
    loginStep2: '/verifica_token',
    saveApostaFutebol: '/aposta_online_fut',
    aposta: '/aposta/get_one',
    smsPulo: '/aposta/envia_sms',
    sorteios: '/sorteios',
    solicita_retirada: '/solicita_retirada',
    oculta_historico: '/oculta_historico',
    providers: '/cassino-provider/lista',
    jogoById: '/get_game_by_id',
    gerarPixRecarga: '/gera_pix_recarga',
    cassino_jogos_by_tag: '/lista_cassino_by_tag',
    cassinoTags: '/cassino-game/lista_tags',
    cassinoJogos: '/cassino-game/lista',
    cassinoProviders: '/cassino-provider/lista',
    cassinoGetProvider: '/cassino-provider/get_by_slug',
    getUsuario: '/get_usuario',
  },
  gets: {
    jb_init: '/jb/init',
    aposta: '/aposta/get_one',
    historico: (page = 0, qtd = 10) => `/get_historico/${page}/${qtd}`,
    verifica_pix: '/verifica_transacao',
    user_apostas: '/get_apostas',
    user_saldo: '/get_saldo',
    user_auth_token: '/usuario/auth/',
    get_solicitacao: '/get_solicitacao/',
    soccerResults: '/resultados',
    soccer: {
      events: '/eventos',
    },
    eventos_destaques: '/eventos_destaques',
    bicho_do_dia: '/bicho_do_dia',
  },
};

const getHeaders = async sessao => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    platform: 'FUTEBOL_SITE',
    'empresa-id': '5f5927a90ab43ddd7da94545',
  };
  sessao = localStorage.getItem('SESSAO');

  let reference = localStorage.getItem('ref');
  if (!!reference) {
    reference = JSON.parse(reference);
    headers['ref-id'] = reference._id;
  }
  if (!!sessao) {
    sessao = JSON.parse(sessao);
    headers['authorization'] = sessao?.token || '';
  }
  return headers;
};

export const apiRequest = async ({
  url,
  method = 'GET',
  data = null,
  log = false,
}) => {
  try {
    let _headers = await getHeaders();
    let requestBody = {
      method,
      url: mainPath + url,
      headers: _headers,
    };
    if (data) requestBody['data'] = JSON.stringify(data);
    if (log) console.log(JSON.stringify(requestBody, null, 2));
    let response = await axios(requestBody);

    return response.data;
  } catch (error) {
    let err = 'Não concluído, tente novamente mais tarde.';
    if (!!error?.response?.data?.message) {
      err = error?.response?.data?.message;
    } else if (
      error?.response?.status == 401 ||
      (error?.response?.status == 403 && typeof window !== 'undefined')
    ) {
      localStorage.removeItem('SESSION');
      window.location.replace('/');
    }
    throw err;
  }
};

export default api_routers;
