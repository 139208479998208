import { useEffect, useState } from "react";
import { BsExclamationDiamond } from 'react-icons/bs'

export default function InlineLoading({ className, loading }) {
    if (!loading) return null
    return (
        <div className={`m-2 ${className}`}>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status" className="ps-1">Carregando...</span>
        </div>
    )

}