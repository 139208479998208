import dayjs from 'dayjs';

class Printer {
    
    INITIALIZE_PRINTER;
    FONT_DOUBLE;
    FONT_BASIC;
    FONT_DEFAULT;
    PRINT_AND_FEED_PAPER;
    SELECT_BIT_IMAGE_MODE;
    SET_LINE_SPACING;
    RESET;
    ALING_CENTER;
    BUFFER;
    SERVICE;

    constructor() {
        this.INITIALIZE_PRINTER = [0x1b, 0x40];
        this.FONT_DOUBLE = [0x1b, 0x21, 0x10];
        this.FONT_BASIC = [0x1b, 0x21, 0x01];
        this.FONT_DEFAULT = [0x1b, 0x21, 0x00];
        this.PRINT_AND_FEED_PAPER = [0x0a];
        this.SELECT_BIT_IMAGE_MODE = [0x1b, 0x2a];
        this.SET_LINE_SPACING = [0x1b, 0x33];
        this.RESET = [0x1b, 0x40];
        this.ALING_CENTER = [0x1b, 0x61, 0x01];
        this.BUFFER = [];
        this.SERVICE = null;
    }

    initBuffer() {
        this.BUFFER = [];
    }

    getBuffer() {
        return this.BUFFER;
    }

    texto(texto = "", alinha = 0, size = 32) {
        let enc = new TextEncoder();
        let texto_quebrado = this.wordWrap(this.retira_acentos(texto), size)
            .split("\n")
            .map((text) => {
                return this.alinha(text.trim(), size, alinha);
            })
            .join("");
        this.BUFFER = [...this.BUFFER, ...enc.encode(texto_quebrado)];
    }

    addLine(lines = 1) {
        let array = [];
        for (let i = 0; i < lines; i++) array.push(10);
        this.BUFFER = [...this.BUFFER, ...array];
    }

    setCommand(cmds = []) {
        this.BUFFER = [...this.BUFFER, ...cmds];
    }

    setReset() {
        this.setCommand([this.RESET]);
    }

    setFontSize(size = 0) {
        this.setCommand(size ? this.FONT_DOUBLE : this.FONT_DEFAULT);
    }


    ajusteEspacamentoEntreLinhas() {
        this.setCommand([...this.SET_LINE_SPACING, 0x00]);
        this.setCommand([0x1b, 0x32, 0x00]);
    }

    async getPrinterBluetooth() {
        try {
            let serviceId = "e7810a71-73ae-499d-8c15-faa9aef0c3f2";
            let charectId = "bef8d6c9-9c21-4c9e-b632-bd58c1009f9f";
            let options = {
                filters: [{ services: [serviceId] }]
            };
            // console.log("Requesting Bluetooth Device...");
            // console.log("with " + JSON.stringify(options));
            let device = await navigator.bluetooth.requestDevice(options);
            let connect = await device.gatt.connect();
            // console.log("Conectado Ok: ", connect);
            let server = await connect.getPrimaryService(serviceId);
            // console.log("Service Ok: ", server);
            let service = await server.getCharacteristic(charectId);
            // console.log("Characteristic Ok: ", server);
            this.SERVICE = service;
            return service;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    _ConvertBase(num) {
        return {
            from: function (baseFrom) {
                return {
                    to: function (baseTo) {
                        return parseInt(num, baseFrom).toString(baseTo);
                    },
                };
            },
        };
    }

    binToDecimal(num) {
        return this._ConvertBase(num).from(2).to(10);
    }

    geraCaracter(char = "", size = 0) {
        let retorno = "";
        for (let i = 0; i < size; i++) {
            retorno += char;
        }
        return retorno;
    }

    alinha(text = "", size = 0, position = 0) {
        text = String(text);
        text = text.substr(0, size);
        switch (position) {
            case 0:
                return text + this.geraCaracter(" ", size - text.length);
            case 1:
                let sobra = size - text.length;
                let isAdd = sobra % 2;
                sobra = Math.trunc(sobra / 2);
                return (
                    this.geraCaracter(" ", sobra) +
                    text +
                    this.geraCaracter(" ", sobra) +
                    (isAdd ? " " : "")
                );
            case 2:
                return this.geraCaracter(" ", size - text.length) + text;
        }
    }

    wordWrap(str, maxWidth) {
        let done;
        let res;
        let found;
        var newLineStr = "\n";
        done = false;
        res = "";
        while (str.length > maxWidth) {
            found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (this.testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join("");
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join("");
                str = str.slice(maxWidth);
            }
        }

        return res + str;
    }

    testWhite(x) {
        var white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    }

    moneyBrToUs(text) {
        return Number(
            Number((text || "0").split(".").join("").split(",").join(".")).toFixed(2)
        );
    }

    str_split(string, splitLength) {
        if (splitLength === null) {
            splitLength = 1;
        }
        if (string === null || splitLength < 1) {
            return false;
        }

        string += "";
        var chunks = [];
        var pos = 0;
        var len = string.length;

        while (pos < len) {
            chunks.push(string.slice(pos, (pos += splitLength)));
        }

        return chunks;
    }

    ignoraAcentosRegex(string = "") {
        return string
            .replace(/a/g, "[a,á,à,ä]")
            .replace(/e/g, "[e,é,ë]")
            .replace(/i/g, "[i,í,ï]")
            .replace(/o/g, "[o,ó,ö,ò]")
            .replace(/u/g, "[u,ü,ú,ù]");
    }

    retira_acentos(str) {
        let com_acento =
            "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        let sem_acento =
            "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let novastr = "";

        for (let i = 0; i < str.length; i++) {
            let troca = false;
            for (let a = 0; a < com_acento.length; a++) {
                if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                    novastr += sem_acento.substr(a, 1);
                    troca = true;
                    break;
                }
            }
            if (troca == false) {
                novastr += str.substr(i, 1);
            }
        }

        return novastr;
    }

    delay(time = 0) {
        return new Promise((suc) => {
            setTimeout(() => {
                suc({});
            }, time);
        });
    }

    async print(buffer = []) {
        try {
            let service = this.SERVICE || (await this.getPrinterBluetooth());
            let cache = [];
            console.log(`Tamanho do buffer: ${buffer.length}`);
            let countBlock = 0;
            for (let i in buffer) {
                let byte = buffer[i];
                cache.push(byte);
                if (Number(i) % 16 == 0 || buffer.length == Number(i) + 1) {
                    if (buffer.length == Number(i) + 1) console.log("FIM");
                    await service.writeValue(new Uint8Array(cache));
                    countBlock++;
                    console.log(`Block ${countBlock}`);
                    await this.delay(0);
                    cache = [];
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    printImage(img, imageBits) {
        try {
            let buffer = [];
            let widthLSB = img.width & 0xff;
            let widthMSB = (img.width >> 8) & 0xff;
            let selectBitImageModeCommand = [
                ...this.SELECT_BIT_IMAGE_MODE,
                33,
                widthLSB,
                widthMSB,
            ];
            let setLineSpacing24Dots = [...this.SET_LINE_SPACING, 24];
            let setLineSpacing30Dots = [...this.SET_LINE_SPACING, 30];

            buffer = [...buffer, ...this.INITIALIZE_PRINTER];
            buffer = [...buffer, ...setLineSpacing24Dots];

            let offset = 0;
            while (offset < img.height) {
                buffer = [...buffer, ...selectBitImageModeCommand];

                let imageDataLineIndex = 0;
                let imageDataLine = new Array(3 * img.width);

                for (let x = 0; x < img.width; ++x) {
                    for (let k = 0; k < 3; ++k) {
                        let slice = 0;
                        for (let b = 0; b < 8; ++b) {
                            let y = (offset / 8 + k) * 8 + b;
                            let i = y * img.width + x;

                            let v = false;
                            if (i < imageBits.length) {
                                v = imageBits[i];
                            }
                            slice |= (v ? 1 : 0) << (7 - b);
                        }

                        imageDataLine[imageDataLineIndex + k] = slice;
                    }

                    imageDataLineIndex += 3;
                }

                buffer = [...buffer, ...imageDataLine];
                offset += 24;
                buffer = [...buffer, ...this.PRINT_AND_FEED_PAPER];
            }

            buffer = [...buffer, ...setLineSpacing30Dots];
            return buffer;
        } catch (error) {
            console.log(error);
        }
    }

    async printBilhete(soccerBuffer = []) {
        this.initBuffer();
        this.setFontSize(0)
        for (let buff of soccerBuffer) {
            if (!!buff?.texto) {
                let pos = 0;
                if (buff?.texto?.split("")[0] === ' ') pos = 1;
                this.texto(buff.texto, pos);
            }
            if (buff?.tipo == 'linha') this.addLine();
        }

        let buffer = this.getBuffer()
        await this.print(buffer);
    }
}



class Tabela {
    linhas;
    constructor() {
        this.linhas = [];
    }
    addLinha() {
        this.linhas.push([]);
    }
    addColuna(tamanho = 10, alinha = 0) {
        this.linhas[this.linhas.length - 1].push({ tamanho, alinha, linhas: [] });
    }
    quebra_frase(str, maxWidth) {
        str = String(str);
        let newLineStr = "\n";
        let done = false;
        let res = "";
        do {
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (this.testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join("");
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join("");
                str = str.slice(maxWidth);
            }

            if (str.length < maxWidth) done = true;
        } while (!done);

        return res + str;
    }
    testWhite(x) {
        var white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    }
    alinha(text = "", size = 0, position = 0) {
        text = String(text);
        text = text.substr(0, size);
        switch (position) {
            case 0:
                return text + this.geraCaracter(" ", size - text.length);
            case 1:
                let sobra = size - text.length;
                let isAdd = sobra % 2;
                sobra = Math.trunc(sobra / 2);
                return (
                    this.geraCaracter(" ", sobra) +
                    text +
                    this.geraCaracter(" ", sobra) +
                    (isAdd ? " " : "")
                );
            case 2:
                return this.geraCaracter(" ", size - text.length) + text;
        }
    }
    geraCaracter(char = "", size = 0) {
        let retorno = "";
        for (let i = 0; i < size; i++) {
            retorno += char;
        }
        return retorno;
    }
    addTextoColuna(index, texto) {
        let coluna = this.linhas[this.linhas.length - 1][index];
        texto = String(texto);
        let linhas = (texto.length > coluna.tamanho
            ? this.quebra_frase(texto, coluna.tamanho)
            : texto
        )
            .split("\n")
            .map((v) => this.alinha(v.trim(), coluna.tamanho, coluna.alinha));

        coluna.linhas = [...coluna.linhas, ...linhas];
    }
    show() {
        let retorno = [];
        for (let linha of this.linhas) {
            let maiorIndex = 0;
            for (let coluna of linha) {
                if (coluna.linhas.length > maiorIndex)
                    maiorIndex = coluna.linhas.length;
            }
            for (let i = 0; i < maiorIndex; i++) {
                let texto = "";
                for (let coluna of linha) {
                    let text = coluna.linhas[i];
                    if (!text) text = this.geraCaracter(" ", coluna.tamanho);
                    texto += text;
                }
                retorno.push(texto);
            }
        }
        return retorno;
    }
}


export { Printer, Tabela };