import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { FiAlertTriangle } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { SaqueQueries } from "../../api/query/saque";
import { UserQueries } from "../../api/query/user";
import { Button } from "../../components/buttons/button";
import LoadingBalls from "../../components/loading/loading-balls";
import usePagination from "../../hooks/usePagination";
import { gameRequests } from "../../requests/gameRequests";
import { handleText } from "../../utils/handle-text";
dayjs.locale('pt-br');

export function Saques() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pagination } = usePagination();
  const [saques, setSaques] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const clienteInfoQuery = useQuery(UserQueries.getClienteInfos);
  const [showForm, setShowForm] = useState(false);
  const { loading: loading_query } = useQuery(SaqueQueries.getSaques, {
    pollInterval: 3000,
    onCompleted(data) {
      setSaques(data?.getSaques?.saques || []);
      setLoading(false);
    },
    onError(err) {
      setLoading(false);
    },
  });

  const [loading_saque, setLoadingSaque] = useState(false);

  const formikPix = useFormik({
    initialValues: {
      "valor": "",
      "tipo_chave": "",
      "chave_pix": "",
      "nome_favorecido": "",
      "cpf_favorecido": "",
      "salvar": false,
    },
    validationSchema: Yup.object().shape({
      valor: Yup.string().required("Valor obrigatório"),
      tipo_chave: Yup.string().required("Campo obrigatório"),
      chave_pix: Yup.string().required("Campo obrigatório"),
      nome_favorecido: Yup.string().required("Campo obrigatório"),
      cpf_favorecido: Yup.string().required("Campo obrigatório")
    }),
    onSubmit: submit
  })
  useEffect(() => {
    if (formikPix?.values?.tipo_chave) formikPix.setFieldValue("chave_pix", "");
  }, [formikPix.values.tipo_chave])

  useEffect(() => {
    if (showForm) getSavedValues();
  }, [showForm])

  useEffect(() => {
    if (Object.keys(location.search).length) {
      let params = new URLSearchParams(location.search);
      let instrucao = params.get('inst');
      if (instrucao == 'new') {
        setShowForm(true);
        setTimeout(() => {
          document.getElementById("nome-favorecido-input")?.focus();
        }, 150);
      }
    }
  }, [location.search])

  function getSavedValues() {
    if (!!clienteInfoQuery?.data?.getClienteInfos?.dados_pix?.tipo_chave) {
      let p = clienteInfoQuery.data.getClienteInfos.dados_pix;
      console.log(p);
      setTimeout(() => {
        formikPix.setFieldValue("cpf_favorecido", p.cpf_favorecido)
        formikPix.setFieldValue("nome_favorecido", p.nome_favorecido)
        formikPix.setFieldValue("tipo_chave", p.tipo_chave)
        formikPix.setFieldValue("salvar", true);
        setTimeout(() => {
          formikPix.setFieldValue("chave_pix", p.chave_pix)
          setTimeout(() => {
            document.getElementById("valor-pix-input")?.focus();
          }, 150);
        }, 150);
      }, 150);

    }
  }

  async function submit(values) {
    if (loading_saque) return;
    setLoadingSaque(true);
    setError("");
    try {
      let resp = await gameRequests.novoSaque(values)
      if (!!resp?.message) {
        alert(resp.message);
      }
      formikPix.resetForm();
      setShowForm(false);
    } catch (error) {
      let err = 'Ocorreu um problema ao solicitar o saque';
      if (typeof error == 'string') err = error;
      setError(err)
    }
    setLoadingSaque(false);
  }

  return (
    <div className="container py-3">
      <h3>Saques</h3>
      <hr />
      <div className="d-flex flex-row gap-2">
        <div className="bg-success bg-gradient px-3 py-2 d-flex flex-column rounded">
          <span className="fw-bold">Saldo atual</span>
          <span>{handleText.formatValueToMoney(clienteInfoQuery?.data?.getClienteInfos?.saldo || 0)}</span>
        </div>
        {
          !showForm && (
            <Button
              type="button"
              onClick={() => {
                setShowForm(true)
                setTimeout(() => {
                  document.getElementById("nome-favorecido-input")?.focus();
                }, 150);
              }}>
              Novo saque
            </Button>
          )
        }
      </div>
      {
        showForm && (
          <>
            <form className="card p-3 my-3" onSubmit={formikPix.handleSubmit}>
              <span className="fw-bold fs-5">Novo saque</span>
              <small><i>Campos * obrigatórios</i></small>
              <hr />
              <div className="row g-2">
                <div className="col-6">
                  <Form.Group>
                    <Form.Label htmlFor="nome-favorecido-input">Nome do favorecido *</Form.Label>
                    <Form.Control id={'nome-favorecido-input'} placeholder="Nome do favorecido" value={formikPix.values.nome_favorecido} onChange={formikPix.handleChange('nome_favorecido')} />
                    {
                      formikPix.errors?.nome_favorecido && formikPix?.touched?.nome_favorecido && (
                        <small style={{ color: "red" }}>{formikPix.errors?.nome_favorecido}</small>
                      )
                    }
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group>
                    <Form.Label htmlFor="cpf-favorecido-input">CPF do favorecido *</Form.Label>
                    <Form.Control id={'cpf-favorecido-input'} placeholder="CPF do favorecido" value={formikPix.values.cpf_favorecido} onChange={formikPix.handleChange('cpf_favorecido')} />
                    {
                      formikPix.errors?.cpf_favorecido && formikPix?.touched?.cpf_favorecido && (
                        <small style={{ color: "red" }}>{formikPix.errors?.cpf_favorecido}</small>
                      )
                    }
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Tipo da chave PIX *</Form.Label>
                    <div className="d-flex flex-row gap-4" key={'tipo-chave'}>
                      <Form.Check
                        type={'radio'}
                        label={'CPF'}
                        id={'cpf-checkbox'}
                        name={'tipo_chave'}
                        value={'cpf'}
                        onChange={(e) => formikPix.setFieldValue('tipo_chave', e.target.value)}
                        checked={formikPix.values.tipo_chave == 'cpf'}
                      />
                      <Form.Check type={'radio'}
                        label={'E-mail'}
                        id={'email-checkbox'}
                        name={'tipo_chave'}
                        value={'email'}
                        onChange={(e) => formikPix.setFieldValue('tipo_chave', e.target.value)}
                        checked={formikPix.values.tipo_chave == 'email'}
                      />
                      <Form.Check type={'radio'}
                        label={'Telefone'}
                        id={'tel-checkbox'}
                        name={'tipo_chave'}
                        value={'telefone'}
                        onChange={(e) => formikPix.setFieldValue('tipo_chave', e.target.value)}
                        checked={formikPix.values.tipo_chave == 'telefone'}
                      />
                      <Form.Check type={'radio'}
                        label={'Chave aleatória'}
                        id={'chave-checkbox'}
                        name={'tipo_chave'}
                        value={'chave_aleatoria'}
                        onChange={(e) => formikPix.setFieldValue('tipo_chave', e.target.value)}
                        checked={formikPix.values.tipo_chave == 'chave_aleatoria'}
                      />
                    </div>
                    {
                      formikPix.errors?.tipo_chave && formikPix?.touched?.tipo_chave && (
                        <small style={{ color: "red" }}>{formikPix.errors?.tipo_chave}</small>
                      )
                    }
                  </Form.Group>
                </div>
                {
                  !!formikPix?.values?.tipo_chave && (
                    <>
                      <div className="col-6">
                        <Form.Group>
                          <Form.Label htmlFor="chave-pix-input">Chave PIX *</Form.Label>
                          <Form.Control
                            id={'chave-pix-input'}
                            onChange={formikPix.handleChange('chave_pix')}
                            placeholder="Chave PIX"
                            value={formikPix.values.chave_pix}
                          />
                          {
                            formikPix.values.tipo_chave == 'telefone' && (
                              <>
                                <small>Telefone com DDD: (XX)99999-9999</small>
                                <br />
                              </>
                            )
                          }
                          {
                            formikPix.errors?.chave_pix && formikPix?.touched?.chave_pix && (
                              <small style={{ color: "red" }}>{formikPix.errors?.chave_pix}</small>
                            )
                          }
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group>
                          <Form.Label htmlFor="valor-pix-input">Valor *</Form.Label>
                          <CurrencyInput
                            id="valor-pix-input"
                            prefix="R$"
                            value={formikPix.values.valor}
                            className="form-control form-control-sm"
                            placeholder={"Valor"}
                            onValueChange={(value) => formikPix.setFieldValue("valor", value)}
                          />
                          {
                            formikPix.errors?.valor && formikPix?.touched?.valor && (
                              <small style={{ color: "red" }}>{formikPix.errors?.valor}</small>
                            )
                          }
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group>
                          <Form.Check
                            id={'save'}
                            label={'Salvar dados para próximas transferências'}
                            checked={formikPix.values.salvar}
                            onChange={e => formikPix.setFieldValue('salvar', e.target.checked)}
                          />
                        </Form.Group>
                      </div>
                    </>
                  )
                }
              </div>
              {
                error && (
                  <>
                    <div className="bg-danger bg-gradient text-white rounded p-3 mt-2">
                      {error}
                    </div>
                  </>
                )
              }
              <hr />
              <div className="d-flex flex-row gap-2">
                <Button type={'submit'} disabled={loading_saque}>
                  Sacar
                </Button>
                <Button
                  variant={'danger'}
                  type={'button'}
                  onClick={() => {
                    formikPix.resetForm();
                    setShowForm(false);
                    navigate([window.location.pathname], { replace: true })
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </form>

          </>
        )
      }

      {!showForm && (
        <>
          {loading_query && <LoadingBalls />}
          {
            saques.length == 0  && !loading_query && (
              <div className="card p-3 text-center mt-3 align-items-center text-muted gap-2">
                <FiAlertTriangle  className="fs-3"/>
                <div>Você ainda não realizou saques!</div>
              </div>
            )
          }
          
          {
            saques.length > 0 && !loading_query && (
              <>
                {
                  saques?.map((item, index) => {
                    return (
                      <div
                        key={item._id}
                        className="card p-2 gap-1 mt-2"
                      >
                        <div className="flex-row align-items-start justify-content-between gap-3">
                          {
                            !!item?.createdAt && (
                              <div>
                                <div className="text-muted">Data</div>
                                <div className="fw-semibold">{dayjs(Number(item.createdAt)).format("DD [de] MMMM [de] YYYY [às] HH:mm")}</div>
                              </div>
                            )
                          }
                          <div>
                            <div className="text-muted">Transação ID</div>
                            <div className="fw-semibold">{item?.transaction_id || ""} {!item?.transaction_id ? "TRANSAÇÃO PENDENTE" : ""}</div>
                          </div>
                          <div>
                            <div className="text-muted">Nome do favorecido</div>
                            <div className="fw-semibold">{item?.nome_favorecido || "---"}</div>
                          </div>
                          <div>
                            <div className="text-muted">CPF do favorecido</div>
                            <div className="fw-semibold">{item?.cpf_favorecido || "---"}</div>
                          </div>
                        </div>
                        <div className="flex-row align-items-start justify-content-between gap-3">
                          <div>
                            <div className="text-muted">Tipo da chave</div>
                            <div className="fw-semibold">{item?.tipo_chave || "---"}</div>
                          </div>
                          <div>
                            <div className="text-muted">Chave PIX</div>
                            <div className="fw-semibold">{item?.chave_pix || "---"}</div>
                          </div>
                          <div>
                            <div className="text-muted">Valor</div>
                            <div className="fw-semibold">{handleText.formatValueToMoney(item.valor)}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </>

            )
          }
        </>
      )}
    </div>
  );
}