import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import InlineLoading from '../../../components/inline-loading';
import handleRouter from '../../../utils/handle-router';
import { useFormik } from 'formik';
import { apiRequest } from '../../../api';
import * as Yup from 'yup';
import { UsuarioMutations } from '../../../api/mutation/usuario';
import { useMutation } from '@apollo/client';
import handleErrorGql from '../../../utils/handle-error-gql';

export default function ClientesFormColaborador() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [appClienteFormMutation] = useMutation(UsuarioMutations.deleteClientePonto)


    useEffect(() => {
        if (!!location?.search) {
            let url = new URLSearchParams(location.search);
            formik.setFieldValue('_id', url.get('id'));
            formik.setFieldValue('nome', url.get('nome'));
            formik.setFieldValue('telefone', url.get('telefone'));
        }
    }, [location])

    const handleSubmit = async (values) => {
        if (loading) return;
        setLoading(true);
        try {
            let data = await appClienteFormMutation({
                variables: {
                    id: values._id,
                    nome: values.nome,
                    telefone: values.telefone
                }
            })
            if (data.errors) throw data.errors;
            window.history.back();
        } catch (error) {
            let err = handleErrorGql(error);
            alert(err);
        }
        setLoading(false);

    }

    const formik = useFormik({
        initialValues: {
            _id: "",
            nome: "",
            telefone: ""
        },
        validationSchema: Yup.object({
            nome: Yup.string().required('Campo obrigatório')
        }),
        onSubmit: handleSubmit
    })





    return (
        <div className="container pt-3">
            <div className="d-flex flex-row align-items-center gap-2">
                <div className="card px-2 py-1 rounded bg-danger text-white border-0 shadow-sm" style={{ cursor: 'pointer' }}>
                    <div className="d-flex flex-row align-items-center gap-2" onClick={() => window.history.back()}>
                        <BsArrowLeft /> <span className='d-none d-sm-block'>Voltar</span>
                    </div>
                </div>
                <span className='fs-4 fw-bold'>Editar cliente</span>
            </div>
            <hr />
            <InlineLoading loading={loading} />
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="row mb-2 g-2">
                    <div className="col-md-6">
                        <label htmlFor="nome" className="form-label">Nome</label>
                        <input type="text" className="form-control" id="nome" name="nome" value={formik.values.nome} onChange={formik.handleChange} />
                        {formik.touched.nome && formik.errors.nome ? <small className="text-danger">{formik.errors.nome}</small> : null}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="telefone" className="form-label">Telefone</label>
                        <input type="text" className="form-control" id="telefone" name="telefone" value={formik.values.telefone} onChange={formik.handleChange} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-sm" disabled={loading}>Salvar</button>
            </form>
        </div >
    );
}

