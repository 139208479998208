import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FutQuerys } from '../../api/query/fut';
import { gameRequests } from '../../requests/gameRequests';
import { CardJogo } from '../Conta/components/modal-detalhado';
import { handleText } from '../../utils/handle-text';
import { useUsuarioContext } from '../../context/context-user';
import { Printer } from '../../printer';
import packageJson from '../../../package.json';
import axios from 'axios';
import { mainPath } from '../../api';
import { FaDownload } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function ConferirBilhete() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [consultaBilheteQuery] = useLazyQuery(FutQuerys.consultaBilheteFutebol);
  const [bilhete, setBilhete] = useState(null);
  const navigate = useNavigate();
  const { colaborador } = useUsuarioContext();
  const isJb = location.pathname.search('jb') == 1;
  const [getSoccerBufferLazy] = useLazyQuery(gql`
    query Query($apostacod: String, $isFirst: Boolean, $versionCode: String) {
      getSoccerBuffer(apostacod: $apostacod, isFirst: $isFirst, versionCode: $versionCode)
    }
  `)


  const formik = useFormik({
    initialValues: {
      codigo: '',
    },
    onSubmit: submit,
  });

  useEffect(() => {
    if (Object.keys(location.search).length) {
      let params = new URLSearchParams(location.search);
      let codigo = params.get('codigo');
      if (!!codigo) {
        getBilhete(codigo);
        formik.setFieldValue('codigo', codigo);
      }
    }
  }, [location.search]);


  const handleBluetoothConnection = async () => {
    try {
      let response = await getSoccerBufferLazy({
        variables: {
          apostacod: bilhete.cod,
          isFirst: true,
          versionCode: `TJBet Web ${packageJson.version}`
        }
      })
      if (response?.data?.getSoccerBuffer) {
        let json = JSON.parse(response.data.getSoccerBuffer)
        let printer = new Printer();
        await printer.printBilhete(json);
      }
    } catch (error) {
      console.log('Error connecting to Bluetooth device:', error);
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  };

  const shareBilhete = async ({ cod }) => {
    if (isMobile()) {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Compartilhar Bilhete ' + cod,
            text: 'Confira o bilhete:' + cod,
            url: window.location.href,
          });
        } catch (error) {
          console.log('Error sharing:', error);
        }
      } else {
        if (navigate?.clipboard?.writeText) {
          console.log("clipboard not exists")
          navigator.clipboard.writeText(window.location.href).then(
            () => {
              alert('Link copiado para a área de transferência!');
            },
            (err) => {
              console.error('Erro ao copiar o link: ', err);
            }
          );
        } else {
          console.log("clipboard not exists")
        }
      }
    } else {
      navigator.clipboard.writeText(window.location.href).then(
        () => {
          alert('Link copiado para a área de transferência!');
        },
        (err) => {
          console.error('Erro ao copiar o link: ', err);
        }
      );
    }
  }

  async function getBilhete(codigo) {
    setLoading(true);
    try {
      if (!isJb) {
        let { data, error } = await consultaBilheteQuery({
          variables: { codigo },
          fetchPolicy: 'no-cache',
        });
        if (error) throw error;
        if (!!data?.futebolsite_consultaBilheteFutebol) {
          let bilhete_json = JSON.parse(
            data.futebolsite_consultaBilheteFutebol
          );
          setBilhete(bilhete_json);
        }
      } else {
        let data = await gameRequests.getBilhetesJB(codigo);
        setBilhete(data[0] || false);
      }
    } catch (error) {
      setBilhete(null);
      alert(error);
    }
    setLoading(false);
  }

  async function submit({ codigo }) {
    let path = window.location.pathname + '?codigo=' + codigo;
    if (!codigo) setBilhete(null);
    navigate(path);
  }

  function dataMovimento(data) {
    return data.split('T')[0].split('-').reverse().join('/');
  }


  function renderImprimirImpressoraTermica() {
    let bol = false;
    if (isAndroid) bol = true;
    if (isDesktop) bol = true;
    return bol
  }

  function isAndroid() {
    return /android/i.test(navigator.userAgent);
  };

  function isDesktop() {
    return !/android|iPad|iPhone|iPod/i.test(navigator.userAgent);
  };


  async function downloadBilhete({ cod }, output, newWindow = window) {
    try {
      let qs = new URLSearchParams();
      qs.append("codigo", cod);
      qs.append("output", 'html');
      let server = mainPath + '/esportes/bilhete?' + qs.toString();
      let response = await axios({ url: server, responseType: 'text' });

      const tempDiv = newWindow.document.createElement('div');
      tempDiv.innerHTML = response.data;
      newWindow.document.body.appendChild(tempDiv);

      let canvas = await html2canvas(tempDiv);

      const imgData = canvas.toDataURL('image/jpeg', 0.5);
      let orientation = 'portrait';
      let format = [canvas.height, canvas.width];
      if (isDesktop()) {
        orientation = 'landscape';
        format = [canvas.width, canvas.height];
      }

      const pdf = new jsPDF({
        orientation,
        unit: 'px',
        format,
        putOnlyUsedFonts: true,
        floatPrecision: 16
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      if (output == 'pdf') {
        pdf.save(`Bilhete ${cod}.pdf`);
        newWindow.document.body.removeChild(tempDiv);
      }
      if (output == 'img') {
        const link = newWindow.document.createElement('a');
        link.href = imgData;
        link.download = `Bilhete ${cod}.jpg`;
        link.click();
      }
      // if (output == 'share-img') {
      //   if (navigator.share) {

      //     const blob = await dataURLToBlob(imgData);
      //     const file = new File([blob], `Bilhete ${cod}.jpg`, { type: 'image/jpeg' });
      //     try {
      //       await navigator.share({
      //         title: `Bilhete ${cod}`,
      //         text: 'Confira este bilhete!',
      //         files: [file],
      //       });
      //     } catch (error) {
      //       console.error('Erro ao compartilhar:', error);
      //     }
      //   } else {
      //     console.log('Compartilhamento não suportado pelo navegador');
      //   }
      // }
      // if (output == 'share-pdf') {
      //   if (navigator.share) {
      //     const pdfBlob = pdf.output('blob');
      //     const pdfFile = new File([pdfBlob], `Bilhete ${cod}.pdf`, { type: 'application/pdf' });
      //     try {
      //       await navigator.share({
      //         files: [pdfFile],
      //       });
      //     } catch (error) {
      //       console.error('Erro ao compartilhar o PDF:', error);
      //     }
      //   } else {
      //     console.log('Compartilhamento não suportado pelo navegador.');
      //   }
      // }
      tempDiv.remove();
    } catch (error) {
      console.error('Erro ao baixar o bilhete:', error);
    }
  }

  function retornaCard() {
    if (!bilhete) return null;

    if (bilhete?.futebol) {
      return (
        <div className=" d-flex justify-content-center align-items-center mt-2">
          <div className="col-md-6 col-12 border px-2 py-4 shadow-sm d-flex flex-column">
            <h5 className="text-center fw-bold">TJBET</h5>
            <hr />
            <small>
              <b>Bilhete:</b> {bilhete.cod} - <span className={`${bilhete?.status == 'CANCELADO' ? 'text-danger fw-bold' : ''}`}>{bilhete.status}</span>
            </small>
            <small>
              <b>Data:</b>{' '}
              {bilhete?.is_site
                ? dayjs(bilhete.data_movimento).format('DD/MM/YY HH:mm:ss')
                : dayjs(bilhete.data_movimento)
                  .add(3, 'h')
                  .format('DD-MM-YYYY')}
            </small>
            {!!bilhete?.ponto?._id && (
              <small>
                <b>Colaborador:</b> {bilhete.ponto.cod} - {bilhete.ponto.nome}
              </small>
            )}
            {!!bilhete?.cliente_ponto_app?._id && (
              <small>
                <b>Cliente:</b> {bilhete.cliente_ponto_app.nome} {bilhete.cliente_ponto_app?.telefone ? ` - ${bilhete.cliente_ponto_app.telefone}` : ''}
              </small>
            )}
            <hr />
            {bilhete.jogos_futebol?.map(item => {
              return (
                <div
                  key={'item-jogo-' + item._id}
                  className="d-flex flex-column"
                >
                  <small className="fw-bold">
                    {dayjs(item.data_hora).format('DD/MM/YYYY [as] HH:mm')}
                  </small>
                  <small>{item.liga.nome}</small>
                  <small className="fw-semibold">
                    {item.casa} x {item.fora}
                  </small>
                  <small>{item.cotacao.sigla.split(';')[1]}</small>
                  <div className="d-flex flex-row justify-content-between">
                    <small>{item.cotacao.sigla.split(';')[2]}</small>
                    <small>{item.cotacao.valor.toFixed(2)}</small>
                  </div>
                  {renderStatusJogo(item)}
                  <hr />
                </div>
              );
            })}
            <div className="d-flex flex-row justify-content-between">
              <small>Jogos</small>
              <small>{bilhete.total_jogos_futebol}</small>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <small>Cotação</small>
              <small>{bilhete.cotacao_bilhete.toFixed(2)}</small>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <small>Valor total</small>
              <small>{bilhete.valor_bruto.toFixed(2)}</small>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <small>Possível retorno</small>
              <small>{bilhete.possivel_premio.toFixed(2)}</small>
            </div>
            {
              bilhete?.status != 'CANCELADO' && (
                <div
                  className={`d-flex flex-row justify-content-between ${bilhete?.status_premio_futebol == 'GANHOU' ? 'fw-bold' : ''}`}
                >
                  <small>Status</small>
                  <small>{bilhete.status_premio_futebol}</small>
                </div>
              )
            }
            {bilhete?.valor_premios > 0 &&
              bilhete.status_premio_futebol == 'GANHOU' && (
                <div className="d-flex flex-row justify-content-between fw-bold">
                  <small>Valor Prêmio</small>
                  <small>{bilhete.valor_premios.toFixed(2)}</small>
                </div>
              )}
            {!!bilhete?.rodape && (
              <>
                <hr />
                <small
                  style={{ whiteSpace: 'break-spaces' }}
                  className="text-center"
                >
                  {bilhete.rodape}
                </small>
              </>
            )}

            {
              colaborador && (
                <div className='d-flex flex-column gap-2'>
                  <hr />
                  {
                    renderImprimirImpressoraTermica() && (
                      <Button
                        onClick={() => handleBluetoothConnection()}
                      >
                        Imprimir (Impressora Térmica Bluetooth)
                      </Button>
                    )
                  }
                  <Button
                    onClick={() => downloadBilhete(bilhete, 'pdf')}
                  >
                    <FaDownload /> Bilhete (PDF)
                  </Button>
                  <Button
                    onClick={() => downloadBilhete(bilhete, 'img')}
                  >
                    <FaDownload /> Bilhete (Imagem)
                  </Button>
                  <Button
                    onClick={() => shareBilhete(bilhete)}
                  >
                    {isDesktop() ? 'Copiar link' : 'Compartilhar'}
                  </Button>
                </div>
              )
            }

          </div>
        </div>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center align-items-center mt-2">
          <div className="col-md-6 col-12 border px-2 py-4 shadow-sm d-flex flex-column">
            <h5 className="text-center fw-bold">TJBET</h5>
            <hr />
            <small>
              <b>Bilhete:</b> {bilhete.cod} - {bilhete.status}
            </small>
            <small>
              <b>Emitido em:</b>{' '}
              {dayjs(bilhete.createdAt).format('DD/MM/YY HH:mm')}
            </small>
            <hr />
            {bilhete.jogos?.map((jogo, index) => {
              return <CardJogo key={index} {...jogo} />;
            })}

            <div className="d-flex flex-row justify-content-between">
              <small>Extração</small>
              <small>{bilhete.extracao.descricao}</small>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <small>Data</small>
              <small> {dataMovimento(bilhete.data_movimento)}</small>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <small>Valor total</small>
              <small>{handleText.asMoney(bilhete.valor_bruto)}</small>
            </div>

            {bilhete.valor_premios > 0 && bilhete?.status == 'VALIDO'(
              <div className={`badge text-bg-primary text-light my-2`}>
                PREMIADO
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="container">
      <div className="px-3 ">
        <h1>Conferir bilhete</h1>
        <form onSubmit={formik.handleSubmit} className="row gap-2">
          <div className="input-group">
            <input
              id="bilhete-input"
              className="form-control"
              placeholder="Código do bilhete"
              onChange={formik.handleChange('codigo')}
              value={formik.values.codigo}
            />
            <Button className="z-0" disabled={loading} type="submit">
              Buscar
            </Button>
          </div>
        </form>
      </div>

      {bilhete?.status == 'AGUARDANDO VALIDACAO' &&
        bilhete.forma_pagamento == 'COLABORADOR' && (
          <p className="p-2 bg-danger text-white text-center rounded shadow my-2">
            <b style={{ color: 'yellow' }}>ATENÇÃO!</b>
            <br />
            Este bilhete precisa ser validado em um colaborador: <br />
            <b>{bilhete.cod}</b>
          </p>
        )}
      {retornaCard()}
    </div>
  );
}

function renderStatusJogo(jogo) {
  if (jogo?.cancelado) return <small className="fw-bold">CANCELADO</small>;
  if (!jogo?.cancelado) {
    if (jogo?.apurado && jogo?.acertou)
      return <small className="fw-bold">ACERTOU</small>;
    if (jogo?.apurado && !jogo?.acertou)
      return <small className="fw-bold">ERROU</small>;
    if (!jogo?.apurado) return <small className="fw-bold">EM ABERTO</small>;
  }
  return null;
}
