import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useLazyQuery } from '@apollo/client';
import { UserQueries } from '../../../api/query/user';
import InlineError from '../../../components/inline-error';
import { Button } from '../../../components/buttons/button';
import { ContextUsuario } from '../../../context/context-user';
import handleRouter from '../../../utils/handle-router';

export default function LoginColaborador() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setSessaoColaborador, getSessaoColaborador } = useContext(ContextUsuario);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const formik = useFormik({
        initialValues: {
            value: '',
            senha: ''
        },
        validationSchema: Yup.object({
            value: Yup.string().required('Campo obrigatório'),
            senha: Yup.string().required('Campo obrigatório'),
        }),
        onSubmit: submit,
    });


    const [queryLogin] = useLazyQuery(UserQueries.appLogin);


    useEffect(() => {
        verificaSessao();
    }, [])


    async function verificaSessao() {
        let sessao = await getSessaoColaborador();
        if (sessao) redirectInicio()
    }

    function redirectInicio() {
        navigate(handleRouter.login_colaborador_inicio);
    }

    async function submit({ ...payload }) {
        if (loading) return;
        setLoading(true);
        setError(null)
        try {
            let data = await queryLogin({
                variables: { value: payload.value, senha: payload.senha },
                fetchPolicy: 'no-cache',
                nextFetchPolicy: 'no-cache',
            });
            if (data?.errors?.length) throw data.errors[0];
            if (data?.data?.appLogin?.token) {
                setSessaoColaborador(data.data.appLogin);
                redirectInicio()
            }
        } catch (err) {
            setError(err)
        }
        setLoading(false);
    }


    return (
        <div className="container pt-3">
            <div className="d-flex justify-content-center">
                <form
                    onSubmit={formik.handleSubmit}
                    className="card col-12 col-md-12 col-lg-6"
                    noValidate
                >
                    <div className="card-body">
                        <h5 className="card-title text-center fw-bold">Login</h5>
                        <div className="mb-3">
                            <label htmlFor="value" className="form-label">Código ou nome de usuário *</label>
                            <input
                                type="text"
                                className={`form-control ${formik.touched.value && formik.errors.value ? 'is-invalid' : formik.touched.value && !formik.errors.value ? 'is-valid' : ''}`}
                                id="value"
                                placeholder="Código ou nome de usuário *"
                                {...formik.getFieldProps('value')}
                            />
                            {
                                formik.errors.value && formik.touched.value ? (
                                    <div className="invalid-feedback">{formik.errors.value}</div>
                                ) : null
                            }

                        </div>
                        <div className="mb-3">
                            <label htmlFor="senha" className="form-label">Senha *</label>
                            <input
                                type="password"
                                className={`form-control ${formik.touched.senha && formik.errors.senha ? 'is-invalid' : formik.touched.senha && !formik.errors.senha ? 'is-valid' : ''}`}
                                id="senha"
                                placeholder="Senha *"
                                {...formik.getFieldProps('senha')}
                            />
                            {
                                formik.errors.senha && formik.touched.value ? (
                                    <div className="invalid-feedback">{formik.errors.value}</div>
                                ) : null
                            }
                        </div>
                        <InlineError error={error} />
                        <Button className="w-100" type="submit" disabled={loading}>
                            Entrar
                        </Button>
                    </div>
                </form>
            </div >
        </div >
    );
}

