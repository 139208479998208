import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft, BsCurrencyDollar, BsEye, BsX } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserQueries } from '../../../api/query/user';
import { Button } from '../../../components/buttons/button';
import MoneyBRL from '../../../utils/money-brl';
import handleErrorGql from '../../../utils/handle-error-gql';
import dateSimple from '../../../utils/date-simple';
import handleRouter from '../../../utils/handle-router';
import InlineLoading from '../../../components/inline-loading';

export default function BilhetesFutebolColaborador() {
    const navigate = useNavigate();
    const location = useLocation();
    // const { setSessaoColaborador, getSessaoColaborador } = useContext(ContextUsuario);

    const [loading, setLoading] = useState(false);
    const [statusPremios, setStatusPremio] = useState([
        {
            label: "Todos",
            value: "TODOS"
        },
        {
            label: "Em aberto",
            value: "EM ABERTO"
        },
        {
            label: "Ganhou",
            value: "GANHOU"
        },
        {
            label: "Perdeu",
            value: "PERDEU"
        }
    ])
    const [bilhetesFutebol, setBilhetesFutebol] = useState([]);

    const [pagarApostaFutebolMutation] = useMutation(UserQueries.pagoApostaFUT);
    const [cancelarApostaFutebolMutation] = useMutation(UserQueries.cancelaApostaFUT);



    useEffect(() => {
        if (!location?.search) {
            let url = getURLSearch()
            navigate(location.pathname + "?" + url, { replace: true });
        } else {
            let url = new URLSearchParams(location.search);
            formik.setFieldValue('data', url.get('data') || dayjs().format('YYYY-MM-DD'));
            formik.setFieldValue('status', url.get('status') || "TODOS");
            formik.setFieldValue('busca', url.get('busca') || "");
            getBilhetesFutebol();
        }
    }, [location])

    const [getBilhetesFutebolLazy] = useLazyQuery(UserQueries.appGetApostasPonto);

    const handleSubmit = () => {
        let url = getURLSearch();
        navigate(location.pathname + "?" + url, { replace: true });
    }

    const getURLSearch = () => {
        let url = new URLSearchParams();
        url.append("data", formik.values.data);
        url.append("status", formik.values.status);
        url.append("busca", formik.values.busca);
        return url.toString()
    }

    const getBilhetesFutebol = async () => {
        setLoading(true);
        try {
            let params = formik.values;
            let data = await getBilhetesFutebolLazy({
                variables: {
                    busca: params.busca,
                    data: params.data,
                    status: params.status
                },
                fetchPolicy: 'no-cache',
                nextFetchPolicy: 'no-cache',
            })
            if (data?.errors?.length) throw data.errors[0];
            setBilhetesFutebol(data.data.appGetApostasPonto.lista);
        } catch (error) {

        }
        setLoading(false);
    }

    const pagarBetFut = async ({ _id, cod, status_premio_futebol }) => {
        if (loading) return;
        if (window.confirm(`Deseja marcar o bilhete: ${cod} como PAGO?`)) {
            setLoading(true);
            let reload = false;
            try {
                await pagarApostaFutebolMutation({
                    variables: {
                        pagoApostaFutId: _id
                    }
                })
                reload = true;
                alert(`Bilhete ${cod} marcado como pago!`);
            } catch (error) {
                let err = handleErrorGql(error);
                alert(err);
            }
            setLoading(false);
            if (reload) handleSubmit();
        }
    }
    const cancelarBetFut = async ({ _id, cod }) => {
        if (loading) return;
        if (window.confirm(`Deseja cancelar o bilhete ${cod}?`)) {
            setLoading(true);
            let reload = false;
            try {
                await cancelarApostaFutebolMutation({
                    variables: {
                        cancelaApostaFutId: _id
                    }
                })
                reload = true;
                alert(`Bilhete ${cod} cancelado!`);
            } catch (error) {
                let err = handleErrorGql(error);
                alert(err);
            }
            setLoading(false);
            if (reload) handleSubmit();
        }
    }

    const formik = useFormik({
        initialValues: {
            busca: "",
            data: dayjs().format('YYYY-MM-DD'),
            status: ""
        },
        onSubmit: handleSubmit
    })

    return (
        <div className="container py-3">
            <div className="d-flex flex-row align-items-center gap-2">
                <div className="card px-2 py-1 rounded bg-danger text-white border-0 shadow-sm" style={{ cursor: 'pointer' }}>
                    <div className="d-flex flex-row align-items-center gap-2" onClick={() => navigate(handleRouter.login_colaborador_inicio)}>
                        <BsArrowLeft /> <span className='d-none d-sm-block'>Voltar</span>
                    </div>
                </div>
                <span className='fs-4 fw-bold'>Bilhetes - Futebol</span>
            </div>
            <hr />

            <form onSubmit={formik.handleSubmit}>
                <div className="row mb-3 g-2">
                    <div className="col-md-3">
                        <div className="form-floating">
                            <input type="date" className="form-control" value={formik.values.data} onChange={formik.handleChange('data')} />
                            <label>Data</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating">
                            <input type="text" className="form-control" placeholder="Buscar: codigo do bilhete, nome do cliente" value={formik.values.busca} onChange={formik.handleChange('busca')} />
                            <label>Buscar: codigo do bilhete, nome do cliente</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-floating">
                            <select className="form-select" id="floatingSelect" value={formik.values.status} onChange={formik.handleChange('status')}>
                                {
                                    statusPremios.map((data, index) => {
                                        return <option key={index} value={data.value}>{data.label}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect">Status do prêmio</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mb-3">
                    <Button disabled={loading} type="submit" >
                        Buscar
                    </Button>
                </div>
            </form>


            <div className="row g-2">
                {
                    bilhetesFutebol?.map((item) => {
                        return (
                            <div key={item._id} className="col-md-4">
                                <div className={`card p-3 shadow-sm ${item?.premio_pago_status ? 'bg-success border-0' : ''} ${item?.status == 'CANCELADO' ? 'bg-danger border-0 text-white' : ''}`}>
                                    <div className="d-flex flex-row justify-content-between fw-bold">
                                        <span>Bilhete</span>
                                        <span>{item.cod}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Data</span>
                                        <span>{dateSimple(item.data_movimento, 0, 'DD/MM HH:mm')}</span>
                                    </div>
                                    {
                                        item?.status == 'VALIDO' && (
                                            <div className="d-flex flex-row justify-content-between">
                                                <span>Status&nbsp;prêmio</span>
                                                <span>{item.status_premio_futebol}</span>
                                            </div>
                                        )
                                    }
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Status&nbsp;aposta</span>
                                        <span>{item.status}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Jogos</span>
                                        <span>{item.total_jogos_futebol}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Cotação</span>
                                        <span>{item.cotacao_bilhete.toFixed(2)}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Valor</span>
                                        <span>R${MoneyBRL(item.valor_bruto)}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Possível prêmio</span>
                                        <span>R${MoneyBRL(item.possivel_premio)}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <span>Comissão</span>
                                        <span>R${MoneyBRL(item.comissao_ponto)}</span>
                                    </div>
                                    <div className="d-flex flex-row gap-2 mt-2">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            type='button'
                                            onClick={() => navigate(handleRouter.conferir_bilhete + `?codigo=${item.cod}`)}
                                        >
                                            <BsEye /> Bilhete
                                        </button>
                                        {
                                            !item?.premio_pago_status && item?.status_premio_futebol == 'GANHOU' && (
                                                <button className='btn btn-primary btn-sm' onClick={() => pagarBetFut(item)}>
                                                    <BsCurrencyDollar /> <small>Pagar</small>
                                                </button>
                                            )
                                        }
                                        {
                                            item?.status == 'VALIDO' && (
                                                <button className='btn btn-danger btn-sm' onClick={() => cancelarBetFut(item)}>
                                                    <InlineLoading loading={loading} />
                                                    {
                                                        !loading && (
                                                            <>
                                                                <BsX /> <small>Cancelar</small>
                                                            </>
                                                        )
                                                    }
                                                </button>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    );
}

