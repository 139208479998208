import { gql } from "@apollo/client";

export const UserQueries = {

  changePassword: gql`
  mutation ChangePassword($password: String, $pontoId: String) {
    changePassword(password: $password, pontoId: $pontoId)
  }
  `,
  
  getAppClienteList: gql`
  query Query($busca: String) {
    appClienteList(busca: $busca)
  }
  `,

  buscaApostaFutebol: gql`
  query BuscaApostaFutebol($cod: String) {
  buscaApostaFutebol(cod: $cod) {
    _id
    cod
    cliente_ponto_app{
      _id
      nome
      telefone
    }
    jogos_futebol {
      _id
      base_id
      casa
      fora
      data_hora
      liga {
        id
        nome
      }
      cotacao {
        id
        sigla
        sigla_id
        valor
      }
      iniciou
    }
    valor_bruto
  }
}
  `,

  cancelaApostaFUT: gql`
    mutation CancelaApostaFUT($cancelaApostaFutId: String) {
      cancelaApostaFUT(id: $cancelaApostaFutId)
    }
  `,
  pagoApostaFUT: gql`
    mutation PagoApostaFUT($pagoApostaFutId: String) {
      pagoApostaFUT(id: $pagoApostaFutId)
    }
  `,
  appGetApostasPonto: gql`
    query AppGetApostasPonto($data: String, $status: String, $busca: String) {
      appGetApostasPonto(data: $data, status: $status, busca: $busca) {
        lista {
          _id
          cod
          data_movimento
          valor_bruto
          comissao_agente
          comissao_ponto
          valor_liquido
          valor_premios
          possivel_premio
          status_premio_futebol
          cotacao_bilhete
          empresa
          status
          tipo
          premio_pago_status
          premio_pago_por{
            datahora
          }
          cliente_ponto_app{
            _id
            nome
            telefone
          }
          total_jogos_futebol
          ponto{
            cod
            nome
          }
          agente{
            cod
            nome
          }
          jogos_futebol {
            _id
            cotacao {
              id
              sigla
              sigla_id
              valor
            } 
            liga{
              nome
            }
            placar_casa
            placar_fora
            data_hora
            casa
            fora
            cancelado
            apurado
            ao_vivo
            acertou
          }
          createdAt
          acertos
          total_pules
          data_premio
          paga
          texto_apoio
        }
        totais
      }
    }
  `,

  appRelatorioCambistaPeriodo: gql`
  query Query($idprocess: String, $dataInicial: String, $dataFinal: String) {
    appRelatorioCambistaPeriodo(idprocess: $idprocess, dataInicial: $dataInicial, dataFinal: $dataFinal)
  }
  `,
  appGetSemanasFutebol: gql`
    query Query {
      appGetSemanasFutebol
    }
  `,

  appLogin: gql`
    query AppLogin($value: String, $senha: String) {
    appLogin(value: $value, senha: $senha) {
      _id
      cod
      nome
      status
      status_futebol
      deviceIds
      token
      limite_diario
      limite_semanal
    }
  }
  `,

  getClienteInfos: gql`
    query {
      getClienteInfos {
        saldo
        pixs {
          nome
          chave_pix
        }
        dados_pix{
          tipo_chave
          chave_pix
          nome_favorecido
          cpf_favorecido
        }
      }
    }
  `,
  getApostasUsuarioSite: gql`
    query GetApostasUsuarioSite(
      $data: String
      $busca: String
      $pagina: Int
      $porpagina: Int
      $filtros: String
    ) {
      getApostasUsuarioSite(
        data: $data
        busca: $busca
        pagina: $pagina
        porpagina: $porpagina
        filtros: $filtros
      ) {
        lista {
          _id
          cod
          cotacao_bilhete
          paga
          forma_pagamento
          status
          valor_bruto
          status_premio_futebol
          data_movimento
          possivel_premio
          futebol
          jogos_futebol{
            _id
          }
          valor_premios
            jogos {
              _id
            }
         extracao {
            descricao
            }
         
        }
      }
    }
  `,
  getApostaDetalhadaUsuarioSite: gql`
    query GetApostasUsuarioSite($_id: String) {
      getApostaDetalhadaUsuarioSite(_id: $_id) {
        _id
        cod
        data_movimento
        valor_bruto
        valor_liquido
        valor_premios
        possivel_premio
        total_jogos_futebol
        forma_pagamento
        acertos
        status
        status_premio_futebol
        cotacao_bilhete
        paga
        futebol
        jogos {
          _id
          dados
          premios
          siglas
          ids
          cotada
          valor
          premiado
          acertados
          limite
          valor_premio
          dados_acertados {
            premio
            sigla
            valor_premio
            valor
            dados
          }
        }
         extracao {
            _id
            cod
            descricao
            hora_sorteio
            status
            dia_excecao
          }
        jogos_futebol {
          placar_casa
          placar_fora
          data_hora
          cancelado
          iniciou
          acertou
          apurado
          cotacao {
            ao_vivo
            sigla
            valor
          }
          liga {
            nome
          }
          casa
          fora
          _id
        }
        pagstar_pix{
          qr_code_url
          favoured
          external_reference
          checkout
          pix_key
          expiration
          expire_at
          generated_at
        }
        cancelamento {
          datahora
        }
      }
    }
  `,
  atualizandoUsuarioSite: gql`
    query {
      atualizandoUsuarioSite {
        _id
        nome
        cpf
        telefone
        email
        status
        saldo
        is_colaborador
      }
    }
  `,
};
