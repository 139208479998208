import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BsArrowLeft, BsCash, BsDoorOpen, BsReceiptCutoff } from 'react-icons/bs'
import handleRouter from '../../../utils/handle-router';
import { useNavigate } from 'react-router-dom';
import { UserQueries } from '../../../api/query/user';
import { useLazyQuery, useMutation } from '@apollo/client';
import handleErrorGql from '../../../utils/handle-error-gql';
import MoneyBRL from '../../../utils/money-brl';
import dateSimple from '../../../utils/date-simple';
import InlineLoading from '../../../components/inline-loading';
import { Button } from '../../../components/buttons/button';
import { useUsuarioContext } from '../../../context/context-user';

export default function AlterarSenhaColaborador() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [changePassword] = useMutation(UserQueries.changePassword)
    const { colaborador } = useUsuarioContext();

    useEffect(() => {
    }, [])

    const onSubmit = async (values) => {
        if (loading) return;
        setLoading(true);
        try {
            console.log("OK");
            // Call your API to change the password here
            let data = await changePassword({
                variables: {
                    password: values.newPassword,
                    pontoId: colaborador._id
                },
                fetchPolicy: 'no-cache'
            })
        } catch (error) {
            handleErrorGql(error);
        } finally {
            formik.resetForm();
        }
        setLoading(false);
    }

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(4, 'A senha deve ter pelo menos 4 caracteres')
                .required('Campo obrigatório'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'As senhas devem coincidir')
                .required('Campo obrigatório')
        }),
        onSubmit: onSubmit
    });



    return (
        <div className="container pt-3">

            <div className="d-flex flex-row align-items-center gap-2">
                <div className="card px-2 py-1 rounded bg-danger text-white border-0 shadow-sm" style={{ cursor: 'pointer' }}>
                    <div className="d-flex flex-row align-items-center gap-2" onClick={() => navigate(handleRouter.login_colaborador_inicio)}>
                        <BsArrowLeft /> <span className='d-none d-sm-block'>Voltar</span>
                    </div>
                </div>
                <span className='fs-4 fw-bold'>Alterar senha</span>
            </div>

            <hr />

            <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">Nova Senha</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                        placeholder='Nova senha'
                    />
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                        <div className="text-danger">{formik.errors.newPassword}</div>
                    ) : null}
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Confirmar Nova Senha</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        placeholder='Confirme a nova senha'
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="text-danger">{formik.errors.confirmPassword}</div>
                    ) : null}
                </div>
                <Button type="submit" disabled={loading}>
                    {loading ? <InlineLoading /> : 'Alterar Senha'}
                </Button>
            </form>


        </div >
    );
}

