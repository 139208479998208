import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { UserQueries } from '../../../api/query/user';
import { useUsuarioContext } from '../../../context/context-user';
import dateSimple from '../../../utils/date-simple';
import handleErrorGql from '../../../utils/handle-error-gql';
import { handleText } from '../../../utils/handle-text';
import { Button } from '../../buttons/button';
import CardFinalizaAposta from '../../cards/card-finaliza-aposta';
import CardFinalizaApostaJB from '../../cards/card-finaliza-aposta-jb';
import InlineLoading from '../../inline-loading';
import { useTicketContext } from '../main/components/ticket';
import { LabelValor } from './components/label-valor';
import { useFormik } from 'formik';
import { UsuarioMutations } from '../../../api/mutation/usuario';

export default function Finish() {
  const {
    usuario,
    header,
    form,
    loading,
    apostar,
    cart,
    setCart,
    setForm,
    setValorAposta,
    valorAposta,
    // JB
    isJb,
    headerJb,
    apostaJB,
    loadingJb,
    betStates,
    forma_pagamento
  } = useTicketContext();
  const { colaborador } = useUsuarioContext();
  const [loadingBilhete, setLoadingBilhete] = useState(false);
  const [buscaApostaFutebolLazy] = useLazyQuery(UserQueries.buscaApostaFutebol);
  const [getClientesColaboradorLazy] = useLazyQuery(UserQueries.getAppClienteList);

  const [formasPagamento, setFormasPagamento] = useState([]);;
  const [showModal, setShowModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);


  useEffect(() => {
    if (!!form?.isValidatingBilhete) {
      setFormasPagamento([
        {
          label: "Validar bilhete",
          value: "VALIDANDO",
          id: 'group-payment-4',
        }
      ])
      setForm(_ => ({ ..._, forma_pagamento: 'VALIDANDO' }));
    } else {
      setFormasPagamento(forma_pagamento);
    }
  }, [form.isValidatingBilhete])

  const buscaApostaFutebol = async (ev) => {
    ev.preventDefault();
    if (loadingBilhete) return;
    setLoadingBilhete(true);
    try {
      let data = await buscaApostaFutebolLazy({ variables: { cod: form.buscaCod }, fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' });
      if (data?.errors?.length) throw data.errors[0];
      montaApostaFutebol(data.data.buscaApostaFutebol);

    } catch (error) {
      let err = handleErrorGql(error);
    }
    setLoadingBilhete(false);
  }

  const montaApostaFutebol = (bilhete) => {
    if (!bilhete?._id) return;
    try {
      let jogos_bilhete = [];
      for (let jogo of bilhete.jogos_futebol) {
        let _ = {
          _id: jogo._id,
          base_id: jogo.base_id,
          casa: jogo.casa,
          fora: jogo.fora,
          cotacao_selecionada: {
            id: jogo.cotacao.id,
            sigla: jogo.cotacao.sigla,
            sigla_id: jogo.cotacao.sigla_id,
            valor: jogo.cotacao.valor
          },
          data_hora: jogo.data_hora,
          data_hora_br: dateSimple(jogo.data_hora, 0, 'DD/MM HH:mm'),
          liga: jogo.liga,
        }
        jogos_bilhete.push(_);
      }
      setCart(jogos_bilhete);
      let valor_bilhete = 100;
      if (bilhete?.valor_bruto > 0) valor_bilhete = bilhete.valor_bruto * 100;
      setValorAposta(handleText.maskNumber(valor_bilhete));
      setForm(_ => ({
        ..._,
        cliente: {
          _id: bilhete?.cliente_ponto_app?._id || null,
          nome: bilhete?.cliente_ponto_app?.nome || null,
          telefone: bilhete?.cliente_ponto_app?.telefone || null
        },
        isValidatingBilhete: bilhete.cod,
        isValidatingBilheteId: bilhete._id
      }));
    } catch (error) {

    }
  }

  const clearValidation = () => {
    setForm(_ => ({
      ..._,
      cliente: { _id: null, nome: null, telefone: null },
      isValidatingBilhete: null, isValidatingBilheteId: null, buscaCod: ""
    }))
    setCart([]);
    setValorAposta(handleText.maskNumber(null));
  }

  const getClientes = async () => {
    if (loadingClientes) return;
    setLoadingClientes(true);
    try {
      let data = await getClientesColaboradorLazy({ busca: '' });
      if (data?.errors?.length) throw data.errors[0];
      if (data.data.appClienteList) setClientes(JSON.parse(data.data.appClienteList));
    } catch (error) {
      let err = handleErrorGql(error);
      alert(err);
    }
    setLoadingClientes(false);

  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [appClienteFormMutation] = useMutation(UsuarioMutations.deleteClientePonto)

  const handleSubmitCliente = async (values) => {
    if (loadingClientes) return;
    setLoadingClientes(true);
    let reload = false;
    try {
      let data = await appClienteFormMutation({ variables: { nome: values.nome } });
      if (data?.errors) throw data.errors;
      if (!!data.data?.appClienteForm) {
        let cli = JSON.parse(data.data.appClienteForm);
        setForm(_ => ({ ..._, cliente: cli }));
        handleCloseModal();
        formikCliente.resetForm();
      }
      reload = true;
    } catch (error) {
      let err = handleErrorGql(error)
      alert(err);
    }
    setLoadingClientes(false);
    if (reload) getClientes();

  }

  const formikCliente = useFormik({
    initialValues: {
      nome: ''
    },
    onSubmit: handleSubmitCliente
  })

  return (
    <aside
      style={{ height: '100vh' }}
      className="col-md-3 d-none d-md-flex  flex-column  ">
      <div
        className="overflow-y-auto px-2   d-flex flex-column gap-2 py-3"
        style={{ background: '#fff', height: '100%' }}
      >
        {
          !isJb && colaborador && (
            <div className='card bg-primary border-0 p-2'>
              {
                !!!form?.isValidatingBilhete && (
                  <form onSubmit={buscaApostaFutebol} className="d-flex flex-column gap-2">
                    <label className='text-white'>Validação de Bilhete</label>
                    <div className='d-flex flex-row gap-2'>
                      <input
                        type="text"
                        className='form-control form-control-sm'
                        placeholder='Código do bilhete'
                        disabled={!!form.isValidatingBilhete}
                        value={form.buscaCod}
                        onChange={(ev) => setForm(_ => ({ ..._, buscaCod: ev.target.value }))}
                      />
                      <button
                        className='btn btn-secondary btn-sm'
                        type='submit'
                        disabled={loadingBilhete}
                      >
                        <BiSearch />
                      </button>
                    </div>
                    <InlineLoading className={'text-white'} loading={loadingBilhete} />
                  </form>
                )
              }
              {
                !!form?.isValidatingBilhete && (
                  <>
                    <small className='text-white fw-bold'>Validando o bilhete: {form.isValidatingBilhete}</small>
                    <button
                      className="btn btn-danger btn-sm mt-2"
                      onClick={() => clearValidation()}
                    >
                      Remover
                    </button>
                  </>
                )
              }

            </div>
          )
        }
        {isJb
          ? betStates.jogos_salvo?.jogos.map((jogo, index) => (
            <CardFinalizaApostaJB
              key={String(index).toString()}
              item={jogo}
              index={index}
            />
          ))
          : cart?.map(partida => (
            <CardFinalizaAposta {...{ partida }} key={partida?._id} />
          ))}
      </div>

      <div className=" text-light p-2  bg-primary   rounded-top ">
        {
          colaborador && (
            <LabelValor
              className={'text-decoration-underline'}
              onClick={() => handleShowModal()}
              label={'Cliente'}
              valor={!!form?.cliente?.nome ? form?.cliente?.nome : 'Selecione'}
            />
          )
        }
        {(isJb ? headerJb : header).map(item => (
          <LabelValor {...item} key={item.label} />
        ))}
        {form.forma_pagamento === 'SALDO' && (
          <LabelValor
            label={'Saldo'}
            valor={handleText.formatValueToMoney(usuario?.saldo)}
          />
        )}
        <Form.Group>
          {!isJb && (
            <input
              value={valorAposta.maskedValue}
              className="form-control form-control-sm"
              placeholder={'Valor'}
              onChange={({ target }) =>
                setValorAposta(handleText.maskNumber(target.value))
              }
            />
          )}
          <div className="my-2">
            <Form.Label
              title="Formas de pagamento"
              children="Formas de pagamento"
              className="m-0  fw-semibold"
            />
            <Form>
              {formasPagamento.map(item => (
                <Form.Check
                  checked={item.value === form.forma_pagamento}
                  onChange={ev =>
                    setForm(_ => ({
                      ..._,
                      forma_pagamento: item.value,
                    }))
                  }
                  key={item.id}
                  label={item.label}
                  name={'group1'}
                  type={'radio'}
                  id={`inline-${item.id}`}
                />
              ))}
            </Form>
          </div>
        </Form.Group>

        <div className="d-flex flex-column pb-2">
          <Button
            variant="light"
            onClick={isJb ? apostaJB : apostar}
            disabled={loadingJb || loading}
          >
            {(form['forma_pagamento'] === 'PIX' || form['forma_pagamento'] === 'SALDO') && !usuario && 'FAZER LOGIN'}
            {(form['forma_pagamento'] === 'PIX' || form['forma_pagamento'] === 'SALDO') && usuario && 'FINALIZAR'}
            {(!form['forma_pagamento'] || form['forma_pagamento'] === 'COLABORADOR') && 'FINALIZAR'}
            {(form['forma_pagamento'] === 'VALIDANDO') && 'VALIDAR'}
          </Button>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        onShow={getClientes}
      >
        <Modal.Header closeButton>
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineLoading loading={loadingClientes} />

          {
            !!!form?.cliente?._id && (
              <form className="row mb-3 g-2" onSubmit={formikCliente.handleSubmit}>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome do cliente"
                      onChange={formikCliente.handleChange('nome')}
                    />
                    <label>Informe o nome do cliente para criar</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <button className='btn btn-primary btn-sm w-100' type='submit'>
                    Criar
                  </button>
                </div>
              </form>
            )
          }

          {
            !!form?.cliente?._id && (
              <>
                <div className="d-flex flex-column align-items-center mb-2 bg-success p-2 rounded">
                  <span>Cliente selecionado</span>
                  <span>{form.cliente.nome}</span>
                </div>
                <button className={'btn btn-danger btn-sm w-100'}
                  onClick={() => {
                    setForm(_ => ({ ..._, cliente: { _id: null, nome: null, telefone: null } }))
                  }}>
                  Remover seleção
                </button>
              </>
            )
          }
          <hr />

          <div className="row g-2">
            {clientes.map(cliente => (
              <div className="col-md-12" key={cliente._id}>
                <div
                  className="card d-flex flex-column p-2 rounded bg-primary text-white border-0 shadow-sm cursor-pointer"
                  onClick={() => {
                    setForm(_ => ({ ..._, cliente }))
                    handleCloseModal();
                  }}
                >
                  <span>{cliente.nome}</span>
                  <span>{cliente?.telefone || "Sem telefone"}</span>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </aside >

  );
}
