import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserQueries } from "../api/query/user";

export const ContextUsuario = createContext({});

export const useUsuarioContext = () => useContext(ContextUsuario);

export default function ContextUsuarioProvider({ children }) {
  const navigate = useNavigate();
  const [loading_usuario, setLoadingUsuario] = useState(true);

  const [usuario, setUsuario] = useState(null);
  const [colaborador, setColaborador] = useState(null);
  const [fcmToken, setFCMToken] = useState(null);

  const [feedback, setFeedback] = useState(false);
  const [make_login, setMakeLogin] = useState(false);
  const [_reference, _setReference] = useState(null);

  useEffect(() => {
    initializeSessao();
    initializeSessaoColaborador();
  }, []);

  const setReference = (payload) => {
    referenceStorage.setReference(payload);
    _setReference(payload);
    return true;
  }
  const getReference = () => {
    let ref = referenceStorage.getReference();
    return ref;
  }
  const limparReference = () => {
    referenceStorage.limpaReference();
    _setReference(null);
    window.location.replace("/");
    return true;
  }

  const initializeSessao = () => {
    let sessao = usuarioStorage.getSessao();
    if (sessao) setUsuario(sessao);
    let __ref = referenceStorage.getReference();
    if (__ref) _setReference(__ref);
  };

  const setSessao = (payload) => {
    usuarioStorage.setSessao(payload);
    setUsuario(payload);
    return;
  };

  const limparSessao = async () => {
    await usuarioStorage.limpaSessao();
    setUsuario(null);
    window.location.replace("/");
    return;
  };

  const initializeSessaoColaborador = () => {
    let sessao = colaboradorStorage.getSessao();
    if (sessao) setColaborador(sessao);
  };
  const getSessaoColaborador = () => {
    let sessao = null;
    let _ = colaboradorStorage.getSessao();
    if (_) sessao = _;
    return sessao;
  }
  const setSessaoColaborador = (payload) => {
    colaboradorStorage.setSessao(payload);
    setColaborador(payload);
    return;
  };

  const limparSessaoColaborador = async () => {
    await colaboradorStorage.limpaSessao();
    setColaborador(null);
    window.location.replace("/");
    return;
  };

  return (
    <ContextUsuario.Provider
      value={{
        _reference,
        setReference,
        getReference,
        limparReference,
        usuario,
        setUsuario,
        limparSessao,
        setSessao,
        feedback,
        setFeedback,
        make_login,
        setMakeLogin,
        loading_usuario,
        colaborador,
        setColaborador,
        getSessaoColaborador,
        setSessaoColaborador,
        limparSessaoColaborador,
        fcmToken,
        setFCMToken
      }}
    >
      {children}
    </ContextUsuario.Provider>
  );
}

const usuarioStorage = {
  getSessao() {
    let sessao = localStorage.getItem("SESSAO");
    if (sessao) return JSON.parse(sessao);
    return null;
  },
  setSessao(payload) {
    localStorage.setItem("SESSAO", JSON.stringify(payload));
    return true;
  },
  async limpaSessao() {
    await localStorage.removeItem("SESSAO");
    return true;
  },
};

export const colaboradorStorage = {
  getSessao() {
    let sessao = localStorage.getItem("C_SESSAO");
    if (sessao) return JSON.parse(sessao);
    return null;
  },
  setSessao(payload) {
    localStorage.setItem("C_SESSAO", JSON.stringify(payload));
    return true;
  },
  async limpaSessao() {
    await localStorage.removeItem("C_SESSAO");
    return true;
  },

}

const referenceStorage = {
  getReference() {
    let ref = localStorage.getItem("ref");
    if (!!ref) return JSON.parse(ref);
    return null
  },
  setReference(payload) {
    localStorage.setItem("ref", JSON.stringify(payload));
    return true;
  },
  limpaReference() {
    localStorage.removeItem("ref");
    return true;
  }
}
