import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Content from './components/content';
import Feedback from './components/feedback';
import Header from './components/header';
import Session from './components/session';
import Sidebar from './components/sidebar';
import { Ticket, useTicketContext } from './components/ticket';
import handleRouter from '../../../utils/handle-router';
import { useUsuarioContext } from '../../../context/context-user';
import { messaging } from '../../../firebase/firebaseConfig';


export default function MainLayout() {
  const location = useLocation()
  const navigate = useNavigate()
  const { getSessaoColaborador, setSessaoColaborador, fcmToken, setFCMToken } = useUsuarioContext();
  const { cart } = useTicketContext();


  useEffect(() => {
    if (location.pathname == '/') navigate(handleRouter.inicio_esportes)
  }, [])

  useEffect(() => {
    verificarSessaoColaborador()
    // handlePermission()
  }, [])

  const verificarSessaoColaborador = () => {
    let _ = getSessaoColaborador();
    if (_) setSessaoColaborador(_);
  }

  // onMessage(messaging, (payload) => {
  //   // console.log("Message received. ", payload);
  // })

  // const handlePermission = async () => {
  //   if (!Notification) return;
  //   try {
  //     let permission = await Notification.requestPermission();
  //     if (permission != 'granted') return;
  //     const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
  //     setFCMToken(token);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // }

  return (
    <div className="d-grid ">
      <Header />
      <div className="row g-0">
        <Sidebar />
        <Content>
          <Outlet />
        </Content>
        <Ticket />
      </div>
      <Session />
      <Feedback />
    </div>
  );
}
