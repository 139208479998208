import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { FutMutations } from "../../api/mutation/fut";
import { ContextUsuario } from "../../context/context-user";
import { Button } from "../buttons/button";
import InlineError from "../inline-error";



export function Login({ closeModal }) {
  const { setSessao, setFeedback } = useContext(ContextUsuario);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loginMutation] = useMutation(FutMutations.login);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    login: "",
    senha: "",
  });

  async function login(ev) {
    if (loading) return;
    setError(null);
    setLoading(true);
    ev.preventDefault();
    try {
      const _form = ev.currentTarget;
      if (_form.checkValidity() === false) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      setValidated(true);

      let _data = encodeURI(JSON.stringify(form));
      let { data } = await loginMutation({
        variables: {
          data: _data,
        },
      });
      setSessao(data.futebolsite_login);
      clearForm();
      closeModal();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  function clearForm() {
    setForm((_) => ({
      login: "",
      senha: "",
    }));
    setValidated(false);
  }

  return (
    <Form className="card-body" onSubmit={login} validated={validated}>
      <div className="d-flex flex-column gap-2">
        <div>
          <label htmlFor="fone-input">CPF ou Email</label>
          <input
            id="fone-input"
            type="text"
            className="form-control"
            placeholder="CPF ou Email"
            required
            onChange={(ev) => {
              setForm((_) => ({ ..._, login: ev.target.value }));
            }}
            value={form["login"]}
          />
          <small style={{ fontSize: "0.65rem" }}>* CPF: Sem traços e pontos</small>
        </div>
        <div>
          <label htmlFor="password-input">Senha</label>
          <input
            id="password-input"
            type="password"
            className="form-control"
            placeholder="Senha"
            required
            minLength={4}
            onChange={(ev) => {
              setForm((_) => ({ ..._, senha: ev.target.value }));
            }}
            value={form["senha"]}
          />
        </div>
        <InlineError error={error} />
        <div className="d-flex flex-column">
          <Button type="submit" disabled={loading}>
            Entrar
          </Button>
        </div>
      </div>
    </Form>
  );
}
