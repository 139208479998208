import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BsArrowLeft, BsCash, BsDoorOpen, BsReceiptCutoff } from 'react-icons/bs'
import handleRouter from '../../../utils/handle-router';
import { useNavigate } from 'react-router-dom';
import { UserQueries } from '../../../api/query/user';
import { useLazyQuery } from '@apollo/client';
import handleErrorGql from '../../../utils/handle-error-gql';
import MoneyBRL from '../../../utils/money-brl';
import dateSimple from '../../../utils/date-simple';
import InlineLoading from '../../../components/inline-loading';

export default function CaixaFutebolColaborador() {
    const navigate = useNavigate();
    // const location = useLocation();
    // const { setSessaoColaborador, getSessaoColaborador } = useContext(ContextUsuario);
    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [getSemanasFutebolLazy] = useLazyQuery(UserQueries.appGetSemanasFutebol);

    const [loadingCaixa, setLoadingCaixa] = useState(false);
    const [params, setParams] = useState({ idprocess: null, dataInicial: null, dataFinal: null });
    const [getRelatorioCaixaFutebolLazy] = useLazyQuery(UserQueries.appRelatorioCambistaPeriodo);
    const [caixa, setCaixa] = useState(null);
    const [_index, _setIndex] = useState(-1)


    useEffect(() => {
        getSemanasFutebol();
    }, [])

    useEffect(() => {
        if (_index > -1) {
            let item = datas[_index];
            setParams({
                idprocess: item?.idprocess || null,
                dataInicial: item.data_inicial,
                dataFinal: item.data_final,
            });
        }
    }, [_index])

    useEffect(() => {
        if (params?.dataInicial && params?.dataFinal) getCaixaFutebol()
    }, [params])


    const getCaixaFutebol = async () => {
        if (loadingCaixa) return;
        setLoadingCaixa(true)
        try {
            let data = await getRelatorioCaixaFutebolLazy({
                variables: params,
                fetchPolicy: 'no-cache',
                nextFetchPolicy: 'no-cache',
            })
            if (data?.errors?.length) throw data.errors[0];
            if (data?.data?.appRelatorioCambistaPeriodo) {
                let _ = JSON.parse(data.data.appRelatorioCambistaPeriodo);
                setCaixa(_);
            }
        } catch (error) {
            handleErrorGql(error);
        }
        setLoadingCaixa(false)

    }

    const getSemanasFutebol = async () => {
        if (loading) return;
        setLoading(true);
        try {
            let data = await getSemanasFutebolLazy({ nextFetchPolicy: 'no-cache', fetchPolicy: 'no-cache' });
            if (data?.errors?.length) throw data.errors[0];
            if (!!data?.data?.appGetSemanasFutebol) {
                let _ = JSON.parse(data.data.appGetSemanasFutebol);
                setDatas(_);
                if (_?.length) _setIndex(0);
            }
        } catch (error) {
            handleErrorGql(error);
        }
        setLoading(false);
    }

    const onChangeSelect = (ev) => {
        if (ev?.target?.value > -1) _setIndex(ev.target.value);
    }

    return (
        <div className="container pt-3">

            <div className="d-flex flex-row align-items-center gap-2">
                <div className="card px-2 py-1 rounded bg-danger text-white border-0 shadow-sm" style={{ cursor: 'pointer' }}>
                    <div className="d-flex flex-row align-items-center gap-2" onClick={() => navigate(handleRouter.login_colaborador_inicio)}>
                        <BsArrowLeft /> <span className='d-none d-sm-block'>Voltar</span>
                    </div>
                </div>
                <span className='fs-4 fw-bold'>Caixa - Futebol</span>
            </div>
            <hr />

            <div className="form-floating mb-3">
                <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={onChangeSelect} disabled={loadingCaixa} value={_index}>
                    <option value="-1">Selecione</option>
                    {
                        datas.map((data, index) => {
                            return <option key={index} value={index}>{data.label}</option>
                        })
                    }
                </select>
                <label htmlFor="floatingSelect">Data</label>
            </div>
            <InlineLoading loading={loadingCaixa} />

            {
                caixa && (
                    <div className="row g-2">
                        <div className="col-md-6 mb-3 mb-md-0">
                            <div className="card p-3 shadow-sm">
                                <div className="d-flex flex-column fs-5">
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>Período</span>
                                        <span>{caixa.dataExibicao}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between text-primary-emphasis'>
                                        <span>Entradas</span>
                                        <span>{MoneyBRL(caixa.totais.entradas)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between text-primary-emphasis'>
                                        <span>ENT Aberto</span>
                                        <span>{MoneyBRL(caixa.totais.pendentes)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between text-danger'>
                                        <span>Saídas</span>
                                        <span>{MoneyBRL(caixa.totais.saidas)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>Lançamentos</span>
                                        <span>{MoneyBRL(caixa.totais.lancamentos)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>Comissão</span>
                                        <span>{MoneyBRL(caixa.totais.comissao_ponto)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>Total</span>
                                        <span>{MoneyBRL(caixa.totais.total)}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>Saldo</span>
                                        <span>{MoneyBRL(caixa.totais.saldo)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card p-3 shadow-sm">
                                <table className="table table-sm table-borderless">
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>Lançamentos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (caixa?.lancamentos || [])?.map((item) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>{item.tipo_operacao ? "D" : "C"}</td>
                                                        <td>{dateSimple(item.data_movimento)}. {item.descricao}</td>
                                                        <td className='text-end'>{MoneyBRL(item.valor)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }



        </div >
    );
}

