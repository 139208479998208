import { useMutation } from '@apollo/client';
import React, { createContext, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FutMutations } from '../../../../api/mutation/fut';
import { useBetContext } from '../../../../context/betContext';
import { useContextCart } from '../../../../context/context-cart';
import { useContextGameJB } from '../../../../context/context-game-jb';
import { useUsuarioContext } from '../../../../context/context-user';
import handleRouter from '../../../../utils/handle-router';
import { handleText } from '../../../../utils/handle-text';
import Finish from '../../finish';
import TicketMobile from './ticket-mobile';
import TicketMobileJB from './ticketk-mobile-jb';

const TicketContext = createContext({});

export const useTicketContext = () => useContext(TicketContext);

export function Ticket() {
  const { cart, setCart, valorAposta, setValorAposta } = useContextCart();
  const { usuario, colaborador, setFeedback, setMakeLogin } = useUsuarioContext()
  const [mutationAposta, { loading }] = useMutation(FutMutations.apostar);

  // JB_GAME_DATA
  const { betStates, saveBet } = useBetContext();
  const { changeStep, currentStep } = useContextGameJB();
  const navigate = useNavigate();
  const [loadingJb, setLoadingJb] = useState(false);

  const location = useLocation();
  const isJb = location.pathname.search('jb') == 1;

  function enableTicket() {
    return location.pathname == handleRouter.inicio_esportes || location.pathname == handleRouter.inicio_jb || location.pathname == handleRouter.esportes_partida
  }

  const [form, setForm] = useState({
    forma_pagamento: '',
    buscaCod: '',
    cliente: {
      _id: null,
      nome: null,
      telefone: null
    },
    isValidatingBilhete: null,
    isValidatingBilheteId: null
  });

  // não mexer
  const arr_site_colab = [
    {
      label: 'Colaborador',
      value: 'COLABORADOR',
      id: 'group-payment-2',
    },
  ]
  // não mexer
  const arr_site_cliente = [
    {
      label: 'PIX',
      value: 'PIX',
      id: 'group-payment-1',
    },
    ...(usuario?.saldo
      ? [
        {
          label: 'Saldo',
          value: 'SALDO',
          id: 'group-payment-3',
        },
      ]
      : [])
    ,
  ]

  const forma_pagamento = arr_site_cliente;

  function getRawValueAposta() {
    let valor_apostado = 0;
    if (valorAposta) valor_apostado = valorAposta.value;
    return Number(valor_apostado) || 0;
  }

  function calculaCotacaoTotal() {
    let total = 0;
    for (let jogo of cart) {
      total =
        total == 0
          ? jogo?.cotacao_selecionada?.valor
          : total * jogo?.cotacao_selecionada?.valor;
    }
    return total.toFixed(2);
  }

  function getValorApostado() {
    let total = 0;
    if (getRawValueAposta()) {
      total = getRawValueAposta();
    }
    return total;
  }

  function getPossivelRetorno() {
    let possivel_retorno = 0;
    if (getRawValueAposta()) possivel_retorno = getRawValueAposta();
    for (let jogo of cart) {
      if (jogo?.cotacao_selecionada?.valor) {
        possivel_retorno *= 1 * jogo.cotacao_selecionada.valor;
      }
    }
    return possivel_retorno;
  }

  async function apostar() {
    try {
      let { forma_pagamento } = form;
      if ((forma_pagamento === 'PIX' || forma_pagamento === 'SALDO') && !usuario) {
        setMakeLogin(true);
        if (!usuario) throw new Error('Faça login para continuar');
      } else if (forma_pagamento === 'SALDO' && usuario?.saldo < valorAposta.value) {
        throw new Error('Você não possui saldo suficiente');
      }
      let aposta = {
        valor: valorAposta.value,
        forma_pagamento,
      };

      if (!!form?.isValidatingBilheteId) aposta.id_aposta = form.isValidatingBilheteId;
      if (!!form?.cliente?._id) {
        aposta.cliente = {
          _id: form.cliente._id,
          nome: form.cliente.nome,
          telefone: form.cliente.telefone
        };
      }
      aposta['cotacoes'] = cart.map(item => ({
        _id_partida: item._id,
        fixture_id: item.base_id,
        id: item.cotacao_selecionada.id,
        valor: item.cotacao_selecionada.valor,
      }));
      let ref = localStorage.getItem('ref');
      if (!!ref) {
        ref = JSON.parse(ref);
        aposta['ref_id'] = ref._id;
      }

      if(process.env.NODE_ENV =="development") console.log(JSON.stringify(aposta, null, 2));
      
      let response = await mutationAposta({ variables: { dados: aposta } });
      setCart([]);
      setValorAposta('');
      setForm(_ => ({
        ..._,
        forma_pagamento: '',
        isValidatingBilhete: null,
        isValidatingBilheteId: null,
        buscaCod: '',
        cliente: {
          _id: null,
          nome: null,
          telefone: null
        }
      }));

      if (aposta.forma_pagamento == 'COLABORADOR' && colaborador) {
        navigate(handleRouter.conferir_bilhete + `?codigo=${response?.data?.movimentoFutebolSite.cod}`);
      } else if (aposta.forma_pagamento == 'VALIDANDO') {
        navigate(handleRouter.conferir_bilhete + `?codigo=${response?.data?.movimentoFutebolSite.cod}`);
      } else {
        navigate(aposta.forma_pagamento == 'COLABORADOR' ? `${handleRouter.esportes_compartilhar_codigo_bilhete}?codigos=${response.data.movimentoFutebolSite.cod}&futebol=${true}` : `${handleRouter.conta}?id=${response?.data?.movimentoFutebolSite._id}`)
      }
    } catch (error) {
      let err = 'Ocorreu um error';
      if (error?.message) err = error.message;
      setFeedback({
        title: 'Error',
        description: err,
        variant: 'danger',
      });
    }
  }

  const header = [

    {
      label: 'Jogos',
      valor: cart.length,
    },
    {
      label: 'Cotação',
      valor: calculaCotacaoTotal(),
    },
    {
      label: 'Valor total',
      valor: handleText.asMoney(getValorApostado()),
    },
    {
      label: 'Possível retorno',
      valor: handleText.asMoney(getPossivelRetorno()),
    },

  ];

  //DADOS JBBB


  function getExtractionsInString() {
    let selecteds = betStates?.extracoes || [];
    if (!selecteds?.length) return '...';
    return selecteds.map(e => e.descricao).join(' ');
  }

  const apostaJB = async () => {
    try {
      setLoadingJb(true);
      let { forma_pagamento } = form;
      if (
        (forma_pagamento === 'PIX' || forma_pagamento === 'SALDO') &&
        !usuario
      ) {
        setMakeLogin(true);
        if (!usuario) throw new Error('Faça login para continuar');
      }
      let total = getTotal();
      if (forma_pagamento === 'SALDO' && usuario.saldo < total)
        throw new Error('Você não possui saldo suficiente');
      let response = await saveBet(forma_pagamento);
      setLoadingJb(false);
      changeStep(1);
      navigate(response?.forma_pagamento == 'COLABORADOR' ? `${handleRouter.jb_compartilhar_codigo_bilhete}?codigos=${response.apostas.map(item => item.cod).join('+')}&futebol=${false}` : `${handleRouter.conta}?id=${response.apostas[0]._id}`)
    } catch (error) {
      let err = 'Sem conexão com o servidor.';
      if (error?.message) err = error.message;
      if (typeof error == 'string') err = error;
      setFeedback({
        title: 'Error',
        description: err,
        variant: 'danger',
      });
      setLoadingJb(false);
    }
  };

  function getTotal() {
    return (
      (betStates.jogos_salvo?.total || 0) * (betStates?.extracoes?.length || 0)
    );
  }

  const headerJb = [
    {
      label: 'Jogos',
      valor: betStates.jogos_salvo?.jogos.length,
    },
    {
      label: 'Extração',
      valor: getExtractionsInString(),
    },
    {
      label: 'Data',
      valor: betStates.selectedDate,
    },

    {
      label: 'Valor total',
      valor: handleText.asMoney(
        (betStates.jogos_salvo?.total || 0) *
        (betStates?.extracoes?.length || 0)
      ),
    },
  ];

  if (!enableTicket()) return null
  return (
    <TicketContext.Provider
      value={{
        usuario,
        header,
        form,
        loading: loading,
        apostar,
        cart,
        setCart,
        setForm,
        setValorAposta,
        valorAposta,
        //JB
        isJb,
        headerJb,
        getTotal,
        apostaJB,
        loadingJb,
        betStates,
        forma_pagamento
      }}
    >
      <Finish />
      {isJb ? <TicketMobileJB /> : <TicketMobile />}
    </TicketContext.Provider>
  );
}
