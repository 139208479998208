import { gql } from "@apollo/client";

export const UsuarioMutations = {
  solicitaSaquePix: gql`
    mutation SolicitaSaquePix($data: SaquePix) {
      solicitaSaquePix(data: $data)
    }
  `,

  deleteClientePonto: gql`
  mutation AppClienteForm($id: String, $nome: String, $telefone: String, $apagar: Boolean) {
    appClienteForm(_id: $id, nome: $nome, telefone: $telefone, apagar: $apagar)
  }
  `
};
